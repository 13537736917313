import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-disclosure',
  templateUrl: './disclosure.component.html',
  styleUrls: ['./disclosure.component.css']
})
export class DisclosureComponent implements OnInit {
  lastMonthDate;
  emailAddress = 'samir@ssbainnovations.com';
  constructor(
    private cmService:CommonService
  ) { }

  ngOnInit(): void {
    let date = new Date(), y = date.getFullYear(), m = date.getMonth();
    let possibleDate = new Date(y, m + 0, 0);
    this.lastMonthDate = this.cmService.ordinal_suffix_of(possibleDate.getDate()) + ' ' + `<span class="text-uppercase">${(possibleDate.toLocaleString('en-IN', { month: 'long' }))}</span>` + ' ' + possibleDate.getFullYear();
  }

}
