import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
// import { AuthDialogComponent } from '../auth-dialog/auth-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  portalUrl = environment.PORTAL_URL;

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        let burgermenu = document.getElementsByClassName("burgermenu")[0];
        let menusidebar = document.getElementsByClassName("menusidebar")[0];
        if (burgermenu && menusidebar) {
          if (burgermenu.classList.contains("openSidemenu") && menusidebar.classList.contains("openSidemenu")) {
            burgermenu.classList.remove("openSidemenu");
            menusidebar.classList.remove("openSidemenu");
            this.isOpen = false
          }
        }
      }
    })
  }

  ngOnInit(): void {

  }

  isOpen;
  openNavMenu() {
    this.isOpen = !this.isOpen
  }

  dropdownItem: any = {};
  toggleDropdown(menuName, submenu?) {
    this.dropdownItem[menuName] = !this.dropdownItem[menuName];
    if (!submenu) {
      for (const key in this.dropdownItem) {
        if (this.dropdownItem.hasOwnProperty(key)) {
          const element = this.dropdownItem[key];
          if (menuName != key) {
            this.dropdownItem[key] = false
          }
        }
      }
    }
  }

}
