import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CareersComponent } from './careers/careers.component';
import { DisclosureComponent } from './disclosure/disclosure.component';
import { PricingComponent } from './pricing/pricing.component';
import { FnbMediaComponent } from './fnb-media/fnb-media.component';
import { SharedModule } from 'src/app/helpers/shared/shared.module';
import { GrievanceComponent } from './grievance/grievance.component';
import { InvestorCharterComponent } from './investor-charter/investor-charter.component';



@NgModule({
  declarations: [TermsAndConditionsComponent, PrivacyPolicyComponent, CareersComponent, DisclosureComponent, PricingComponent, FnbMediaComponent, GrievanceComponent, InvestorCharterComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class OthersModule { }
