import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-mutual-funds',
  templateUrl: './mutual-funds.component.html',
  styleUrls: ['./mutual-funds.component.css']
})
export class MutualFundsComponent implements OnInit {

  constructor(public cmService:CommonService) { }

  ngOnInit(): void {
  }

}
