<app-loader></app-loader>
<app-header *ngIf="!routeTitle?.includes('campaign')"></app-header>
<main>
  <div class="">
    <router-outlet></router-outlet>
  </div>
</main>
<app-footer *ngIf="!routeTitle?.includes('campaign')"></app-footer>
<a (click)="scrollToTop()" title="Back To Top" id="gotoTop" class="fadeInUp" href="javascript:void(0)"><i
    class="fa fa-angle-up text-white"></i>
</a>
