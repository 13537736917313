import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from "@angular/common/http";

import { Observable, throwError } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { LoaderService } from 'src/app/services/loader.service';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {

  constructor(private loaderService:LoaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

    this.loaderService.show();
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errMsg;
          // Client Side Error
          if (error.error instanceof ErrorEvent) {
            errMsg = `Error: ${error.error.message}`;
          }
          else {
            // Server Side Error
          }

          if (error.status == 302) {
            console.log('Positive response')
          } else if (error.status == 0) {
            alert("An error occured, when we ran the operation. This might be a temporary issue. Wait a few minutes,and then try again.");
          } else if (error.status == 403) {
            alert("Access denied");
          } else {
            return throwError(error);
          }

          return throwError(error);
        }),
        // takeUntil(),
        finalize(() => this.loaderService.hide())
      )
  }
}
