<section class="manager-descriptionwrap">
  <div class="container">
    <div class="manager-img">
      <img
        src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/samir-jayaswal-finbingo.jpg"
        alt="Samir Jaiswal" title="Samir Jaiswal">
    </div>
    <h4>Samir Jayaswal</h4>
    <h5>Director and CEO</h5>
    <div class="manager-social">
      <a href="https://www.linkedin.com/in/samirjayaswal/" target="_blank">
        <i class="fab fa-linkedin"></i>
      </a>
      <!-- <a href="javascript:void(0);" >
        <i class="fab fa-twitter-square"></i>
      </a> -->
      <!-- <a href="javascript:void(0);">
        <i class="fab fa-instagram"></i>
      </a> -->
      <a href="https://www.facebook.com/leosamir" target="_blank">
        <i class="fab fa-facebook-square"></i>
      </a>
    </div>
    <div class="manager-description">
      <p>
        Along with Samir’s educational background, it has been his keen interest in the world of finance that has
        largely
        charted the course of his career. A Computer Science Graduate, he followed it up with an MBA in International
        Finance
        and is currently pursuing his PhD in Financial Markets.
      </p>
      <p>
        In his 20 years of high-quality experience he has held senior positions in renowned companies like Wipro
        Technologies,
        Financial Technologies, ICICI Group, Religare Group, etc.He was also Director of Sales with SunGard (the world’s
        largest
        Financial Software Fortune 500 Company) and a Country Head for India and Sri-Lanka for a leading Israeli
        Software
        Products company.
      </p>
      <p>
        Samir’s role in developing the financial infrastructure building in India has been critical. He has made a
        significant
        contribution to the world of finance when exchanges were being evolved, depository was getting created,
        derivatives were
        launched, private asset management companies were being formed and brokerages were getting institutionalized.
      </p>
      <p>
        He loves sharing his knowledge and has never missed an opportunity to exchange ideas, whether as a speaker at
        conferences, through talk sessions or interviews, or as visiting faculty at a leading B-school. He has
        co-authored a
        book on Technology for Financial Markets which is extensively used for developing exchange trading applications.
        This
        book is also included in the curriculum of several universities worldwide, including the University of Chicago.
      </p>
      <p>
        Outside of work, Samir likes spending time with his daughter and wife, a super-specialist pediatric surgeon in
        Mumbai.
        He likes reading, watching movies and travelling to new destinations.
      </p>
      <a href="javascript:void(0);" routerLink="/team-finbingo" class="btn btn-primary">Back to Team Finbingo</a>
      <br>
      <br>
    </div>
  </div>
</section>