import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SeoService } from './services/seo.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
import { Location } from '@angular/common';
import { CommonService } from './services/common.service';

declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'finbingo';
  seoData;
  routeTitle;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private _seoService: SeoService, private swUpdate: SwUpdate, public urlLocation: Location, private cmService: CommonService
  ) {

    // For google analytics code
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-119774541-1', {
          'page_path': event.urlAfterRedirects
        });
        window.scroll(0, 0);
      };
      this.routeTitle = this.urlLocation.prepareExternalUrl(this.urlLocation.path());
      if (this.router.url == '/blog') {
        location.href = "https://blog.finbingo.com";
      };
    });
    // SEO Logic
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        this._seoService.updateTitle(event['title'] || 'Finbingo – Your 360° Financial Advisor');
        this._seoService.updateDescription(event['description'] || '');
        this._seoService.updateOtherDesc(this.router.url);
        this._seoService.updateOgUrl(location.href);
      });

    // To check is blog url
    this.cmService.checkBlogURL(location.href);
  }

  redirectURLstrings = ['budget-2021-avoid-adventures', 'top-5-mistakes-you-must-avoid-in-your-tax-planning']

  ngOnInit() {

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        alert("We have made some feature updates to Finbingo. \n Please click Reload button to get latest features.");
        window.location.reload();
      })
    }

    window.addEventListener("scroll", this.scroll, true);
  }

  // To scroll to top
  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }

  scrollTOP;
  scroll = (): void => {
    this.scrollTOP =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (this.scrollTOP > 15) {
      document.getElementById("gotoTop").style.display = "block";
      document.getElementById("main-header").classList.add("header-scroll");
    } else {
      document.getElementById("gotoTop").style.display = "none";
      document.getElementById("main-header").classList.remove("header-scroll");
    }
  };

}
