import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-team-finbingo',
  templateUrl: './team-finbingo.component.html',
  styleUrls: ['./team-finbingo.component.css']
})
export class TeamFinbingoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
