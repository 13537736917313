import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  portalURL = environment.PORTAL_URL;

  // For numbers Only
  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  };

  // To make int value
  toParseInt(value: any) {
    return Math.round(value)
  }

  // To check form validity
  checkFormValidity(formGroup: FormGroup) {
    let controlFlag;
    Object.values(formGroup.controls).forEach(control => {
      if (control.invalid) {
        control.markAsTouched();
        controlFlag = false;
      } else {
        control.setErrors(null)
        controlFlag = true;
      }
    });
    if (controlFlag === true && formGroup.valid) {
      return true;
    } else {
      return false
    }
  }

  dynamicArray(num) {
    return [...Array(num)].map((_, i) => ++i + "");
  }

  // To Ordinal Suffix

  ordinal_suffix_of(num) {
    var j = num % 10,
      k = num % 100;
    if (j == 1 && k != 11) {
      return num + `<sup>st</sup>`;
    }
    if (j == 2 && k != 12) {
      return num + `<sup>nd</sup>`;
    }
    if (j == 3 && k != 13) {
      return num + `<sup>rd</sup>`;
    }
    return num + `<sup>th</sup>`;
  }

  isBlogUrl;
  // temp fix for blog url
  checkBlogURL(url) {
    let result = url.replace(/\/$/, "");
    let redirectURLstrings = [
      "top-5-mistakes-you-must-avoid-in-your-tax-planning",
      "budget-2021-avoid-adventures",
      "3-action-points-to-avoid-tax-impact",
      "tax-saving-investments-2021-finbingo",
      "budget-2021-covid-rebate-against-income-tax",
      "ssba-raises-1-million-dollars",
      "part-2-ulip-india-good-concept-scam",
      "ulip-india-good-concept-scam-part-1",
      "indian-property-market-rich-dad-poor-dad",
      "equity-reliable-passive-income-part2",
      "equity-passive-income-part-1",
      "mutual-fund-as-passive-income",
      "ultrasafe-savings-schemes-of-the-central-government",
      "all-about-arogya-sanjeevani-health-insurance-plan",
      "endowment-moneyback-policy",
      "purchase-term-insurance-plan",
      "taxation-of-esops-recent-changes",
      "301-cost-of-inflation-fy-2020-21",
      "standard-poors-bbb-rating-india",
      "pradhan-mantri-vaya-vandana-yojana-pmvvy-modified-2020",
      "impact-on-rupee-against-dollar-due-to-lockdown-2020",
      "faqs-on-sovereign-gold-bonds",
      "partial-withdrawal-nps-covid-19",
      "april-2020-the-markets-have-rallied-but-be-cautious-be-disciplined",
      "credit-risk-fund-overview-and-analysis",
      "timely-move-rbi-to-prevent-spread-of-franklin-templeton-contagion-to-other-debt-funds",
      "ultra-safe-savings-schemes-of-the-central-government",
      "franklin-india-shuts-six-debt-schemes",
      "facebook-logs-in-jio-and-what-it-means-for-india",
      "economy-revival-through-covid-tax",
      "lesson-from-lockdown-never-look-down-investment-for-contingency",
      "crude-oil-output-cut-opec-indian-economy",
      "covid-19-and-economy-the-way-ahead",
      "7th-april-2020-stock-market-rally",
      "tds-refunds-30th-june-impact",
      "taxation-of-dividends-after-budget-2020-21",
      "what-is-360-degree-investment-planning-benefits",
      "no-tds-on-interest-income-up-to-rs-50000-for-senior-citizens",
      "investing-solely-to-avoid-taxes-is-a-bad-wasteful-strategy",
      "is-sip-investment-really-risk-free",
      "what-can-be-the-best-investment-plan-to-secure-children-education",
      "recapitalisation-impact-beyond-the-banking-sector__trashed",
      "how-children-can-be-more-financially-savvy",
      "importance-of-financial-planning-for-women"
    ];
    let myArr = result.split("/");
    var last_element = myArr[myArr.length - 1];
    if (redirectURLstrings.includes(last_element)) {
      this.isBlogUrl = true;
      location.href = "https://blog.finbingo.com/" + last_element;
    } else {
      this.isBlogUrl = false;
    }
  }
}
