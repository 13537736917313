<header id="main-header">
  <nav class="navbar fixed-top">
    <div class="container">
      <a class="navbar-brand" [routerLink]="'/'"><img alt="" class="hideInMobile"
          src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/logo.png"></a>
      <ul class="menus">
        <li class="">
          <span class="hasDropmenu">
            Offerings <i class="far fa-angle-down"></i>
          </span>
          <ul class="menus-dropdown">
            <li>
              <a class="dropdown-item" routerLink="/mutual-funds">Mutual Fund Platform</a>
            </li>
            <li>
              <a class="dropdown-item" routerLink="/portfolio-doctor">Portfolio Doctor</a>
            </li>
            <li>
              <a class="dropdown-item" routerLink="/wealth-builder">Finbingo Wealth
                Builder</a>
            </li>
            <li>
              <a class="dropdown-item" routerLink="/tax-planning-advisor">Finbingo Tax
                Planner</a>
            </li>
            <li>
              <a class="dropdown-item" routerLink="/financial-life-goals">Financial Life
                Goals</a>
            </li>
            <li>
              <a class="dropdown-item" routerLink="/financial-calculators">Financial &#038;
                Tax
                Calculators</a>
            </li>
            <li>
              <a class="dropdown-item" routerLink="/nps">National Pension Scheme</a>
            </li>
          </ul>
        </li>
        <li class="">
          <span class="hasDropmenu">
            About <i class="far fa-angle-down"></i>
          </span>
          <ul class="menus-dropdown">
            <li>
              <a routerLink="/what-is-finbingo-robo-advisor">What is Finbingo?</a>
            </li>
            <li>
              <a routerLink="/our-story">Why Finbingo Exists</a>
            </li>
            <li>
              <a routerLink="/team-finbingo">Team Finbingo</a>
            </li>
            <li>
              <a routerLink="/contact">Contact Us</a>
            </li>
          </ul>
        </li>
        <!-- <li class="">
          <a class="">
            Learn <i class="far fa-angle-down"></i>
          </a>
          <ul class="menus-dropdown">
            <li>
              <a routerLink="/resources">Resources</a>
            </li>
            <li>
              <a routerLink="/blog">Blog</a>
            </li>
          </ul>
        </li> -->
<!--        <li class="nav-item">-->
<!--          <a class="nav-link" href="https://blog.finbingo.com" target="_blank">Blog</a>-->
<!--        </li>-->
        <li class="nav-item">
          <a class="nav-link" routerLink="/pricing">Pricing</a>
        </li>
        <li class="nav-item">
          <div class="btn-holder">
            <a class="nav-link btn btn-orangehomepage" [href]="portalUrl">Sign In/Register</a>
          </div>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/get-started">Get Started</a>
        </li> -->
      </ul>
      <a href="javascript:void(0);" (click)="openNavMenu()" class="burgermenu" [class.openSidemenu]="isOpen">
        <i class="far fa-bars"></i>
      </a>
      <div class="menusidebar" [class.openSidemenu]="isOpen">
        <div class="menusidebar-header">
          <a href="javascript:void(0);" (click)="openNavMenu()">
            <i class="fas fa-times"></i>
          </a>
        </div>
        <ul class="sidebar-menus">
          <li class="">
            <a class="hasmobileDropmenu" href="javascript:void(0);" (click)="toggleDropdown('offerings')">
              Offerings <i class="far fa-angle-down"></i>
            </a>
            <ul class="mobilemenus-dropdown" [class.open]="dropdownItem['offerings']">
              <li>
                <a class="" routerLink="/mutual-funds">Mutual Fund Platform</a>
              </li>
              <li>
                <a class="" routerLink="/portfolio-doctor">Portfolio Doctor</a>
              </li>
              <li>
                <a class="" routerLink="/wealth-builder">Finbingo Wealth
                  Builder</a>
              </li>
              <li>
                <a class="" routerLink="/tax-planning-advisor">Finbingo Tax
                  Planner</a>
              </li>
              <li>
                <a class="" routerLink="/financial-life-goals">Financial Life
                  Goals</a>
              </li>
              <li>
                <a class="" routerLink="/financial-calculators">Financial &#038;
                  Tax
                  Calculators</a>
              </li>
            </ul>
          </li>
          <li class="">
            <a class="hasmobileDropmenu" href="javascript:void(0);" (click)="toggleDropdown('about')">
              About <i class="far fa-angle-down"></i>
            </a>
            <ul class="mobilemenus-dropdown" [class.open]="dropdownItem['about']">
              <li>
                <a routerLink="/what-is-finbingo-robo-advisor">What is Finbingo?</a>
              </li>
              <li>
                <a routerLink="/our-story">Why Finbingo Exists</a>
              </li>
              <li>
                <a routerLink="/team-finbingo">Team Finbingo</a>
              </li>
              <li>
                <a routerLink="/contact">Contact Us</a>
              </li>
            </ul>
          </li>
          <!-- <li class="">
            <a class="hasmobileDropmenu" href="javascript:void(0);" (click)="toggleDropdown('learn')">
              Learn <i class="far fa-angle-down"></i>
            </a>
            <ul class="mobilemenus-dropdown" [class.open]="dropdownItem['learn']">
              <li>
                <a routerLink="/resources">Resources</a>
              </li>
              <li>
                <a routerLink="/blog">Blog</a>
              </li>
            </ul>
          </li> -->

<!--          <li class="nav-item">-->
<!--            <a class="nav-link" href="https://blog.finbingo.com" target="_blank">Blog</a>-->
<!--          </li>-->
          <li class="nav-item">
            <a class="nav-link" routerLink="/pricing">Pricing</a>
          </li>
          <li class="nav-item">
            <div class="btn-holder">
              <a class="nav-link btn btn-orangehomepage" [href]="portalUrl">Sign In/Register</a>
            </div>
          </li>
          <!-- <li class="nav-item">
                  <a class="nav-link" routerLink="/get-started">Get Started</a>
                </li> -->
        </ul>
      </div>
    </div>
  </nav>
</header>
