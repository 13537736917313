<section class="pt-5 pb-0">
  <div class="container finbWbBanner">
    <div class="row text-center justify-content-center">
      <div class="col-lg-10">
        <h1 class="page-bigtitle">Terms and Conditions</h1>
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks">
  <div class="container">
    <div class="">
      <div class="finb-feature-content">
        <p>
          <strong>Terms of Use of Website, Applications, Platform and Services</strong>
        </p>
        <p>
          This page (together with the Privacy Policy) explains the terms of use (“Terms”) of using the website, Application and
          services (“Services”) provided in the Mobile application (also termed as ‘App’) “Finbingo” & website www.Finbingo.com by
          SSBA Innovations Limited (“Finbingo”).
        </p>
        <p>
          If you do not agree with these Terms, please stop using the Services, leave/ uninstall the Website/ Mobile application
          immediately. All the services in Finbingo.com & Finbingo Application is optional to use.
        </p>
        <p>
          Finbingo is an investment service, hereafter also referred to as the ‘platform’ provided by SSBA Innovations Private
          Limited (also referred to as ‘company’).
        </p>
        <p>
          You acknowledge that the Platform is a web & App based service that allows you to avail the services directly from the
          company including its products and services and facilitate communication with them for such services, and other mutual
          fund/s, insurance, tax planning and general investment-related information & strategies. The Company hereby grants you,
          a limited, non-exclusive, non-transferable, royalty free license to use the Platform for the purposes of availing the
          services from Finbingo, collectively hereinafter referred to as (the “Platform Services”).
        </p>
        <p>
          <strong>Eligibility to use the Platform</strong>
          You are of 18 (eighteen) years of age or older and in case of you are acting as guardian on behalf of a minor, you have
          the necessary authority to register/sign up on the Platform for the availing the services on behalf of the minor and
          transact on their behalf; <br>
          You are of legal age to form a binding contract, are not a person barred from receiving the platform services under the
          applicable laws and are competent to enter into a binding contract. In case the company faces scalability problems, it
          reserves the right to refuse access to the platform to new users and any such user who has been suspended or removed by
          the company for any reason whatsoever shall not been entitled to avail the platform services; and
        </p>
        <p>
          You agree to abide by the Terms of Use, offer documents, investor application form, mutual fund account opening form,
          investment account opening form and Risk Disclosure documents and any other information provided by you on and through
          the platform for availing the platform services;
        </p>
        <p>
          You also warrant that the money you invest is from your own bank account (primary holder’s bank account in case of
          Investment Account held jointly), and the same is earned from legitimate means/ sources and remitted through approved
          banking channels; <br>
          You are prohibited & forbidden from marketing, selling, trading, or otherwise transferring your account to another party
          or impersonating any other person for the purposing of creating & generating an account with the Platform.
        </p>
        <p>
          In order to access the Platform Services on the Platform and Your account on the Platform (“<b>Account</b>”), You will have to
          register on the Platform by providing details including but not limited to mobile number, mother’s name, father’s name,
          email address, password, date of birth, gender, Permanent Account Number (PAN), signature, marital status, nominee
          details, cancelled cheque, photograph and video recording, etc. and other information as may be required by the Company
          from time to time. Your Account will be activated once SSBA, undertakes Your KYC verification in accordance with the
          Know Your Client (“<b>KYC</b>”) guidelines issued by the Securities and Exchange Board of India (“<b>SEBI</b>”) based on the
          information provided by You including personal information.
        </p>
        <p>
          We may also ask You for certain financial information, including Your billing address, bank account details, credit card
          number, expiration date and/or other payment related details or other payment method data, and debit instructions or
          other standing instructions to process payments for the Platform Services. The Company may ask You to provide certain
          additional information about Yourself on a case to case basis. You shall ensure and confirm that the Account information
          provided by You is complete, accurate and up-to-date. If there is any change in the Account information, You shall
          promptly update Your Account information on the Platform. If You provide any information that is untrue, inaccurate, not
          current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or if the Company has reasonable
          grounds to suspect that such information is untrue, inaccurate, not current or incomplete, the Company has the right to
          suspend or terminate Your Account and refuse any and all current or future use of the Platform (or any portion thereof)
          at its discretion, in addition to any right that the Company may have against You at law or in equity, for any
          misrepresentation of information provided by You.
        </p>
        <p>
          You will be responsible for maintaining the confidentiality of the Account information and are fully responsible for all
          activities that occur under Your Account and also agree to keep your login credentials safe and confidential at all
          times. You further agree to promptly change your login credentials and inform the Company immediately in case of any
          actual or suspected unauthorized use of Your Account. The Company cannot and will not be liable for any loss or damage
          arising from Your failure to comply with this provision. You may be held liable for losses incurred by the Company or
          any other user of or visitor to the Platform due to authorized or unauthorized use of Your Account as a result of Your
          failure in keeping Your Account information secure and confidential.
        </p>
        <p>
          <strong>Using Our Platform and Services</strong>
        </p>
        <p>
          This “Finbingo Investment Account” facility (“Investment Account”) is provided by Finbingo to enable clients to carry
          out online transactions in the units of the mutual funds, PMS, insurance policies, loans and other financial instrument.
          Finbingo, through SSBA Innovations Limited is empanelled with various AMCs and shall facilitate online
          transactions in mutual funds for its clients through its platform www.Finbingo.com and shall be responsible/ liable for
          all regulatory compliances /contractual obligations with respect to online distribution of units of these financial
          products. Finbingo has entered into agreements with third party technology service providers to connect with banks for
          offering various facilities through the internet, including net banking and payment and settlement facilities in respect
          of payment instructions & settlements initiated by the Client/ customer using online payment facility. Accordingly,
          Investment Account/s is available to those customer or Clients who hold & have a bank account with these banks and have
          availed the facility for online payments / Net Banking. By subscribing to Finbingo, you agree and accept to abide and be
          bound by these terms and conditions. You further agree to execute all the necessary documents which may be needed from
          time to time, to send the necessary dealing details to the Mutual Funds / SEBI/ KRAs, Exchanges/ Registrar & Transfer
          Agents (RTAs) and other business and regulatory agencies to give effect to the transactions made through Finbingo.com.
        </p>
        <p>
          Finbingo.com offers its clients the option to avail 360 degrees financial planning. It may also offer its advisory and
          execution services in multiple combinations as detailed below:
        </p>
        <p>
          Clients may choose to avail of the Advisory Services from Finbingo. This would include detailed risk profiling, goal
          planning, analysis of insurance needs and preparation of an investment & financial planning. As part of the Advisory
          Services plan, Finbingo offers transaction execution and portfolio tracking facilities. Finbingo does not make it
          binding on its clients availing Advisory Services to avail of the transaction execution facilities through Finbingo
          platform and these clients have the option to use Finbingo only for Advisory Services. The client understands that in
          the current data sharing setup amongst businesses, when they use third party execution services providers (other than
          Finbingo.com) for transaction execution, Finbingo may not be able to offer portfolio tracking, portfolio rebalancing and
          any such services efficiently that require awareness of the client’s complete investment portfolio. The client may also
          choose to avail of an Execution Services plan with Finbingo.com. Clients who don’t go through detailed Wealth planning
          through Finbingo wealth builder are free to use execution only services for investments into various financial
          instruments including mutual funds.
        </p>
        <p>
          <strong>Geographical Locations</strong>
        </p>
        <p>
          Please note that not all the Platform Services are available in all geographical areas and depending on Your location,
          you may not be eligible to avail certain Platform Services offered by the Company, Finbingo as the case may be. The
          Company and/or Finbingo reserve the absolute right to determine the availability and eligibility for any of the Platform
          Service offered on the Platform.
        </p>
        <p>
          <strong>Limited Financial Products</strong>
        </p>
        <p>
          Finbingo does not, and is not obliged to, offer all financial products like all mutual fund schemes or all insurance
          policies etc for investments. You acknowledge and understand that the products we provide on our platform is also
          limited by the data we purchase from third parties and our vendors. By limiting the number of schemes and policies on
          the Platform, neither the company nor Finbingo makes any representation as to the quality, bona fides or nature of any
          insurance policy, insurance company, AMC or mutual fund scheme, or any other representation, warranty or guaranty,
          express or implied in respect of such mutual fund schemes or insurance policy. You hereby agree and acknowledge that the
          data and information provided on the Platform does not constitute advice of any nature whatsoever, and shall not be
          relied upon by you while making investment decisions and you will be solely responsible for any investment decisions and
          for the purchase of any mutual funds or insurance policy on the Platform. You understand that the best mutual fund
          scheme or insurance policy for you may not be listed on Finbingo platform. In no event shall the Company and/or Finbingo
          be liable to you for any loss or damage that may cause or arise from or in relation to these Terms of Use and/or due to
          use of this Platform or due to investments made using this Platform, as part of the Platform Services provided to you,
          after availing such services,
        </p>
        <p>
          <strong>Risks from Financial Investing</strong>
        </p>
        <p>
          All clients who avail of Finbingo.com services, acknowledge that they are well informed about the risks of investing in
          financial instruments of any kind. The plain execution services do not include risk profiling, goal planning,
          preparation of investment plans and/or any other advisory services that are offered as part of the Wealth builder. In
          both the Wealth builder plan and the pure execution services plan, the clients take sole and full responsibility of the
          investment choices they make or the performance of their investment portfolio. The client understands that financial
          market or commodity market or real estate market investing involves significant market risk of losing a part of capital
          or full capital. You also understand that financial markets & commodity markets change very minute and second and hence
          requires significant time and attention from your side to protect your portfolio and to grow it.
        </p>
        <p>
          If you buy a regular mutual fund/ insurance/ pension products/ any other financial instrument on the platform, Finbingo
          receives commissions from AMCs/ insurance companies / other financial institution., however, Finbingo is an RIA
          registered with SEBI and hence makes available direct mutual fund plans for which it receives no distributor commission.
          Please note that Finbingo only facilitates the sale of mutual funds through the platform and will not be liable in any
          manner with respect to the mutual fund units allotted to you by the AMC. These units are also maintained by third party
          agencies appointed by the AMC and Finbingo has no control over these units.
        </p>
        <p>
          <strong>Data Quality & Platform availability</strong>
        </p>
        <p>
          The Company and its affiliates have endeavoured to ensure that all the information on the Platform is correct, but the
          Company and/ or its affiliates neither warrants nor makes any representations regarding the quality, accuracy or
          completeness of any data, information regarding the Platform Services or otherwise. The Company shall not be responsible
          for the delay or inability to use the Platform or related functionalities, the provision of or failure to provide
          functionalities, or for any information, software, functionalities and related graphics arising out of the use of the
          Platform or otherwise obtained through the Platform, , whether based on accountability, agreement, contract,
          responsibility, tort, negligence, strict liability or otherwise. Further, the Company shall not be held responsible or
          answerable for non-availability of the Platform during periodic maintenance operations or improvement or any unplanned
          suspension or adjournment of access to the Platform that may occur due to technical reasons or for any reason beyond the
          Company’s control or command. You agree & understand that any material or data or information downloaded or otherwise
          obtained through the Platform is done entirely at Your own caution, discretion and risk, and that You will be solely
          responsible and answerable for any damage or harm to Your computer or electronic systems or loss of data that results
          from the download of such material or data.
        </p>
        <p>
          <strong>Misuse of Platform and Services</strong>
        </p>
        <p>
          Subject to compliance with the Terms of Use, the Company hereby grants You a non-exclusive, limited privilege to access
          and use this Platform. You admit & agree to use the Platform only: (a) for purposes that are permitted & authorized by
          the Terms of Use; and (b) in accordance with any applicable code of conduct, law, rules & regulation or generally
          accepted practices or guidelines. Finbingo (Finbingo.com) is one of India’s most advanced and friendly investing
          service. Please don’t misuse or exploit our Products & Services. For example, don’t disrupt or interrupt our Services or
          try to access or approach them through back end or by using a method other than the web or website and mobile interface
          and the instructions. If you interrupt, damage, destroy or limit the functionality of our Products & Services, we may
          stop providing our Products & Services to you and pursue legal action against you.
        </p>
        <p>
          Using our Services does not give you ownership, right of possession or proprietorship of any intellectual property
          rights, including any trade-marks branding or logos, in our Services or the content you access. You may not use content
          or data from our Services unless you obtain our consent or permission first, and attribute ownership appropriately.
        </p>
        <p>
          You agree that you shall not copy, duplicate, reproduce, replicate, transmit, sell, redistribute, publish, enter into a
          database, display, perform, clone, modify, alter, transmit, license, create derivatives from, transfer or in any way
          exploit any part of any information, content, materials, services available from or through the Finbingo platform. You
          may however, download the app platform for your own personal, internal and non-commercial use. You agree that You will
          not use the Platform in any manner or engage in any activity that may damage, disable or impair or adversely affect the
          use of the platform or interfere with any other users’ use, legal rights, or enjoyment of the platform. Further, you
          agree to not remove any text, copyright or other proprietary notices contained in the content downloaded from the
          platform.
        </p>
        <p>
          <strong>Further, to this, you undertake not to:</strong><br>
          * copy, paste, republish, post, display, translate, transmit, reproduce or distribute any content on the platform
          through any medium without obtaining the necessary authorization from the company;
          <br>
          * Abuse, harass, defame, threaten or otherwise violate the legal rights of others;
          <br>
          * upload or distribute content, data or source code files that contain software or other material protected by
          applicable intellectual property laws unless you own or control the rights thereto or have received all necessary
          consents;
          <br>
          * engage in any activity that interferes with or disrupts access to the Platform (or the hosting services and networks
          which are used by the Platform);
          <br>
          * attempt to gain unauthorized or illicit access to any portion or feature of the platform, any other systems or hosting
          services or networks connected to the platform, to any of the company’s server, or through the platform, by hacking,
          password mining or any other illegitimate means;
          <br>
          * probe, test or scan the vulnerability or limit of the platform or any network connected to the platform
          <br>
          * breach the security or authentication measures of the platform or any network connected to the platform
          <br>
          * trace, reverse look-up, or seek to trace any information on any other user
          <br>
          * Try to check out the source code of the platform,
          <br>
          * exploit the platform or information made available or obtainable or offered by or through the platform, in any way
          whether or not the purpose is to reveal or disclose any information, including but not limited to personal
          identification information, other than your own information & data, as provided and furnished on the platform
          <br>
          * disrupt, interfere or play with the security of, or otherwise cause harm to, the platform, its hosting service,
          systems resources, accounts, passwords, servers or networks connected to or accessible through the platform or any
          affiliated, subscribed or linked sites;
          <br>
          * use any hardware device or software to interfere or attempt to interfere with the proper working of the platform or
          any transaction being conducted on the platform, or with any other person’s use of the platform;
          <br>
          * do anything that impacts the integrity of the platform
          <br>
          * use the technology or platform or any material or content for any purpose that is illegal or unlawful or prohibited or
          unauthorised by these Terms of Use, or to solicit the performance of any illegal activity or other activity which
          infringes and violate the rights of the Company or platform or other third parties
          <br>
          * breach or violate any applicable laws or regulations for the time being in force within or outside India or anyone’s
          right to privacy or personality;
          <br>
          * reconstruction, reverse engineer, modify, copy, distribute, transmit, display, perform, reproduce, publish, license,
          create derivative works from, transfer, or sell any information or software obtained from the Platform.
          <br>
        </p>
        <p>
          <strong>Business and Transaction related risks & conditions</strong>
        </p>
        <p>
          The client understands and agrees that – <br>
          1. Finbingo is only a distributor of mutual funds and not a manufacturer
          <br>
          2. We also wish to disclose that the Customer has unfettered and unrestricted access to the Mutual Fund/ AMC and can
          transact in the same folio for financial / non-financial transactions without routing the transaction(s) through
          Finbingo.
          <br>
          3. All online orders and requests will be completed subject to timelines published
          <br>
          4. You accept & agree and confirm that all the authorised and complete transactions & settlement will be processed at
          the applicable NAV subject to receipt of funds / amount and acceptance / approval of your transaction by the Mutual
          Funds/ RTAs.
          <br>
          5. You agree not to hold Finbingo/ its authorised representatives liable for any loss that may arise to you as a result
          of the incorrect NAV applied on units allotted to you by the Mutual Fund or their RTAs as Finbingo has no role to play
          in it
          <br>
          6. The money debited from your Bank Account shall be collected & received in the pool account of the third party service
          provider and from there it will be credited / transferred to the respective Mutual Funds/ Asset management company
          either on the same day (in case the order is placed prior to or before the cut off time) or the next working day (in
          case the order is placed after or latter than the cut off time). Hence, you agree & accept, understand, admit and
          acknowledge that there would be an interval or interim time lag in transferring / crediting the money or contributed
          amount to the respective mutual fund account from the pool account of the third party service provider.
          <br>
          7. You agree that when contribution or subscription for mutual fund units are remitted through joint bank accounts of
          investors, the default option for requesting or applying for mutual funds unit/s would be in the joint names of all the
          account holders of the bank account. If the payment is not made from a Registered Bank Account or from an account not
          belonging to the first named unit holder, your transaction will be dismiss & rejected.
          <br>
          8. Additionally, investors shall also have an option to apply for units in single name of any one or more names of the
          joint account holders of the bank account with nomination facility subject to terms and conditions.
          <br>
          9. The units of the schemes shall be allotted, redeemed or switched, as the case may be, at the Net Asset Value (NAV)
          prevalent on the date of the transaction request by the Mutual fund, if the application or request for purchase, switch
          or redemption is placed before the cut-off time.
          <br>
          10. You agree that any transaction request falling due on a holiday or after the cut off time mentioned on the website
          or mobile application would be processed on the next working day and respective NAV would be applicable as per the
          Mutual Fund’s Scheme Information Document (SID). The cut-off timings will be before / prior to the statutory cut-off
          timing specified under the SEBI Regulations and the Scheme Information Document (SID) for operational convenience.
          <br>
          11. Holiday means any day which is not a Business Day.
          <br>
          12. The transactions once placed cannot be cancelled.
          <br>
          13. Finbingo /its authorised representative/service provider does not take any responsibility for any delay in
          acceptance / execution or non-execution / rejection of the order by the AMC or their RTA.
          <br>
          14. Once the information is provided by the Mutual Funds for the rejection, the Investor will be informed within 2
          Business Days.
          <br>
          15. You agree that there may be a time lag between the time a transaction is completed and before the same is updated in
          the records of Finbingo
          <br>
          16. There may be a possibility or prospect of variances in data/ values arising out of delayed data feeds, transmission
          losses in electronic data, electronic fund transfer etc. for which Finbingo /its authorised representatives/ shall not
          be liable.
          <br>
          17. You accept & agree to maintain clear balance or funds in your bank account at all times to cover the purchase value
          / investment value and other costs and charges / quantity of units. You are advised to check your daily fund / amount
          transfer limits with your bank before placing the purchase transaction / units in the particular scheme before placing a
          purchase/SIP request.
          <br>
          18. In absence of sufficient funds (in case of purchase) or units (in case of sell/ switch), the transaction shall be
          rejected and Finbingo /its authorised representative/service provider shall not be responsible & not answerable for the
          non-execution / rejection of your instructions/ application.
          <br>
          19. In case of such shortages of funds and units, Finbingo has no liability or responsibility to inform you of potential
          rejections.
          <br>
          20. In case of redemptions, if the number of Units held by the Customer as per the records maintained by AMC and/or the
          Authorized Registrar is less than the number of Units asked or requested to be redeemed, then the available units will
          be redeemed. All units’ holdings in that scheme shall be redeemed, wherever redemption of “all” units is specified/
          mentioned in the request.
          <br>
          21. All requested redemptions shall be subject to the provisions mentioned in the respective Offer Document / Scheme
          Information Document and addenda thereto issued from time to time.
          <br>
          22. You agree that the payment for the transactions undertaken by you through Finbingo Account will be facilitated
          through a bank to bank transfer or payment gateway and Finbingo/ its authorised representatives/ service provider shall
          not be liable or held responsible for any failures in the link or for any fraud or scam (either payment gateway’s and/or
          bank’s end) that could take place or occur or happen at the time of at the time of making payment
          <br>
          23. In case of order rejection of your purchase request by the mutual fund, the amount will be credited into your bank
          account only after the receipt of the refund from the relevant Mutual Fund.
          <br>
          24. Redemption proceeds do not go through us. All redemption proceeds and dividend payouts shall get credited directly
          by the AMC into your bank account provided at the time of registration with Finbingo. This is subject to the bank having
          a direct credit arrangement with the AMC. The list of such banks is mentioned in the Scheme Information Document (SID)
          or Key Information Memorandum (KIM) cum common application form of the respective Mutual Fund aka Asset management
          companies.
          <br>
          25. All the purchase transactions pertaining to mutual funds made on the website or App shall be allotted an online
          folio number by the AMC’s RTA and you will be able to access the transaction account statement in electronic format. You
          will receive the Accounts Statements directly from the Mutual fund house/ AMCs/ RTAs.
          <br>
          26. You agree and acknowledge that transactions done through Finbingo will be tagged with the RIA Code of Finbingo.
          <br>
          27. If the agreement of Finbingo with a Mutual Fund/AMCs is terminated, you would not be allowed to execute through the
          mobile app or website any transaction including but not limited to a transaction for Purchase, Redemption, etc. of the
          Units of that Mutual Fund through Finbingo. But you can transact with them directly and Finbingo will provide you all
          necessary information.
          <br>
          28. You may get a levy/ charges separately by your bank for any payment you may do through them which you must check
          before availing this facility. However, Credit Cards are not accepted for purchase of mutual funds.
          <br>
          29. There may be exit load/any other charge levied by the Mutual Funds will be chargeable in accordance with the Scheme
          Information Documents (SID) /Key Information Memorandum (KIM) and addendums issued thereto from time to time
          (collectively referred/ mentioned to as “scheme related documents”). You shall read all the scheme related documents
          before investing and bear the charges.
          <br>
          30. Investments from residents of United States of America and Canada are not permitted for certain mutual funds.
          Finbingo shall not be liable for rejections of such applications by Mutual Funds, where investor is an US and Canada
          resident.
        </p>
        <p>
          Your data coming from RTAs and AMCs
        </p>
        <p>
          You authorize Finbingo for receiving your account/ investment details and reverse feed files from the AMC & RTA/s. You
          hereby give consent & permission to share and provide the transactions data feed/portfolio holdings & allocation/ NAV
          etc. in respect of your investments under all schemes managed by Finbingo tagged with the SEBI Registered Investment
          Adviser Code of Finbingo.
        </p>
        <p>
          <strong>Nomination to your account</strong>
        </p>
        <p>
          1. Adding Nomination is prudent and shall be mandatory for new folios/accounts opened by individuals especially with
          sole/ single holding and no new folios/ accounts for individuals in single holding shall be opened without nomination.
          However, in case client/ investors do not wish to or apply to nominate must sign separately confirming their
          non-intention to nominate, failing which the application form may be rejected & not approved at the discretion of the
          AMC/Mutual Fund. <br>
          2. Unit holders can nominate maximum upto 3 people (in the manner prescribed under the SEBI Regulations), in whom names
          the Units held by him/her shall vest in the event of his/her death.
          <br>
          3. The percentage of vesting amongst all nominees have to be specified. If it is not specified, the AMC will distribute
          the claim equally amongst all the nominees.
          <br>
          4. In case of more than one nominee, please obtain additional form as available on the website of the AMC and follow the
          instruction for providing the nomination details therein.
          <br>
          5. Nomination made by unitholder in a folio shall be applicable for investments in all the Schemes in the folio or
          account and every new nomination for a folio or account will overwrite the existing nomination. Thus, a new nomination
          request for fresh nomination & request will imply simultaneous cancellation of existing nomination.
          <br>
          6. Nomination can be made by individuals only by applying for / holding units on their own behalf singly or jointly in
          favour of one or more persons. Non-individuals including society, trust, body corporate, partnership firm, Karta of
          Hindu Undivided Family (HUF), holder of Power of Attorney (PoA) cannot nominate. Nomination form/ application cannot be
          signed by Power of attorney (PoA) holders.
          <br>
          7. In case a folio has joint holders, all joint holders should sign the request for nomination /cancellation of
          nomination and submit the same in physical form, even if the mode of holding is not “joint”.
          <br>
          8. A minor can be nominated and in such an event, the name and address of the guardian of the minor nominee shall be
          provided by the unit holder.
          <br>
          9. Nomination can also be in favour of the Union / Central Government, State Government, a local or municipal authority,
          any person designated by virtue of these offices or a religious trust or charitable trust. The Nominee shall not be a
          trust (other than a religious trust or charitable trust), society, body corporate, partnership firm, Karta of Hindu
          Undivided Family or a Power of Attorney holder. A non-resident Indian is allowed & can be a Nominee subject to the
          exchange controls in force, from time to time.
          <br>
          10. Nomination shall not be allowed in a folio or/and account held on behalf of a minor.
          <br>
          11. Nomination in respect of the units stands cancelled upon the transfer of units. Transfer of units in favour of a
          Nominee shall be valid action/ discharge by the AMC against the legal heir.
          <br>
          12. The cancellation of nomination can be made only by those individuals / clients / investors who hold units on their
          own behalf single or jointly and who made / applied / approved the original nomination.
          <br>
          13. On cancellation or revoke of the nomination, the nomination shall stand rescinded / revoke and the AMC shall not be
          under any obligation or committed to transfer the units in favour of the Nominee.
          <br>
          14. Asset Management companies (AMC) requires a physical nomination form to be sent to them to add nominees against your
          investments. A physical nomination form signed by all the account holders is required, If you need to add nominee(s) for
          investments with AMC.
        </p>
        <p>
          <strong>Terms and Conditions for online SIP through NACH/ ECS Mandate</strong>
        </p>
        <p>
          1. You understand and acknowledge that there could be a time gap / interval of at least 15 (Fifteen) days from the date
          of submission of physical mandate, to enable & authorize the activation / registration of online SIP facility, subject
          to the successful validation and approval of the mandate by the Banks. Likewise, investor or client or you understand &
          acknowledge there shall be a minimum gap of at least 30 (Thirty) days from the date of receipt of SIP deactivation
          mandate and last investment made under SIP.
          <br>
          2. Upon successful registration of mandate, you can start the online SIP facility for making investment in mutual funds
          a fixed amount of money at a fixed frequency of time based on your convenience and choice.
          <br>
          3. You grant Authority to Finbingo/ its authorized payment processing service provider (third party service provider) to
          debit your registered Bank Account through National Payments Corporation of India’s NACH (National Automated Clearing
          House) to enable the collection of the SIP installments and transferring to the respective AMC’ s bank account. The
          conditions related to NACH are relevant & applicable as and when such a facility is made available.
          <br>
          4. You agree and acknowledge that in order to enable you to get the NAV on the SIP date (T date) chosen by you, debit of
          the SIP amount from your Bank Account shall happen few days before/ prior to the SIP date (T). e.g. T-2 (two days prior
          to SIP date), T-4 etc., depending upon NACH debit cycle of your Bank, which could be different & vary from location to
          location, as per NACH mechanism. You understand and acknowledge that, Finbingo/ its third party service provider shall
          do a backward calculation from T date and depending upon NACH debit cycle, your account will be debited on T – no. of
          days, such that on T date (SIP day), the funds reach the respective AMC’s Bank account and the transaction data reach
          the respective registrar of AMC, to enable them to allot units as per NAV on SIP date (T date).
          <br>
          5. You agree and shall ensure to keep sufficient funds available in your Bank account at least 7 days prior to SIP date
          (T date). You agree, understand and acknowledge that SIP instalment and allotment of units shall be subject to
          realization of investment amount or funds. You shall not hold Finbingo/ its third party Service Provider liable, in case
          the SIP instalment process could not be completed due to insufficient funds in your Bank account on any particular NACH
          debit cycle date i.e. on T – no. of days. In such case, the SIP instalment shall lapse for that T of the
          daily/weekly/month/bi-monthly/quarter/half year/year as the case may be.
          <br>
          6. You agree, understand and acknowledge that in the entire process there would be an interim time lag in debiting the
          money from your Bank Account and the actual transferring / crediting to the respective AMC’s Bank account, as the money
          after the debited from your Bank Account shall be lying in the NACH debit cycle and / or third party pool account,
          before the actual transfer of funds to mutual funds / AMC’ s bank account on SIP date.
          <br>
          7. You can view the units allotted by AMC/registrar after two days of SIP date (i.e. SIP day + 2working day).
        </p>
        <p>
          <strong>Refund Policy</strong>
        </p>
        <p>
          The transactions & settlement on the Platform with respect & regards to the Platform Services will be completed only
          after successful transfer of money or invested amount from Your registered bank account to the relevant AMC. Please note
          that the mutual fund units will be credited to Your Account within 2 (two) days (T+2) from the date of successful
          transfer of such amount / money. You hereby understand, agree and acknowledge that the transactions on the Platform once
          completed cannot be cancelled or postponed by you. With respect to investments in mutual funds and stocks, in case of
          successful transfer of money from Your account registered with the AMC, however, any failure to reflect the mutual fund
          in the Account, the money would be refunded to your registered bank account within the relevant time period agreed with
          the AMC and Finbingo shall not be responsible for the same.
        </p>
        <p>
          The Refund & Cancellation policy for all payments made towards account opening or any other services or products using
          any mode of payment shall stand as under:
        </p>
        <p>
          The Fees paid towards account opening charges for enabling mutual funds or wealth builder services or tax planning
          service (Financial planning on Finbingo.com), or any other services is non-refundable & non-reimburse. Pick up of
          required documents, forms, papers or deed related to the account opening procedure and process is subject to
          availability of our representatives, given at any particular time and location & places. In case your account has not
          been opened by Team Finbingo, after the tenth day passing by from the day of collection of all necessary supporting
          documents and receipt of all due authorizations from you, you may request / asked for a full refund of the charges as
          paid by you towards account activation / opening. In case you have paid the charges relating to account opening multiple
          times, please contact support and we will initiate the necessary procedure to refund your money. Note: The completion &
          execution of the refund procedure & process is subject to agencies such as banks, payment gateways.
        </p>
        <p>
          <strong>How Finbingo communicates & communication through SMS, Mail and WhatsApp</strong>
        </p>
        <p>
          You hereby expressly agree to receive communication (including transactional and promotional messages) or by way of SMS
          and/or E-mail or through WhatsApp from the Company or Finbingo or any third party in connection with the Platform
          Services or Your registration on the Platform. You can unsubscribe/ opt-out from receiving communications through SMS
          and e-mail anytime by visiting support@finbingo.com
        </p>
        <p>
          <strong>WhatsApp Groups</strong>
        </p>
        <p>
          The Company reserves a right to create any WhatsApp groups (“Groups”) directly or through its representatives
          (hereinafter referred to as “Group Admin”) who are expressly authorized to control, monitor and administer the Groups on
          its behalf, and add you as a member. The usage, membership, and participation in the Group are subject to the following
          terms and conditions:
        </p>
        <p>
          You shall not: <br>
          * use the Groups for circulating, uploading, transmitting, or otherwise making available any information, data or
          content that is unlawful, harassing, threatening, harmful, tortuous, defamatory, libellous, abusive, violent, obscene,
          vulgar, invasive of another’s privacy, hateful, racially or ethnically offensive, or otherwise objectionable;
          <br>
          * stalk, harass, threaten or harm another user using the Groups;
          <br>
          * engage in any patent or copyright violation and infringement or other intellectual property infringement, or disclose
          any trade secret or confidential or personal information in violation of a confidentiality, employment, or nondisclosure
          agreement and/or privacy norm to which you are subject to;
          <br>
          * Junk mail, spam, or chain letters, including, without limitation, bulk commercial advertising, post, send, transmit or
          otherwise make available any unsolicited or unauthorized e-mail messages, advertising, promotional materials, , and
          informational announcements;
          <br>
          * Store, upload, post, e-mail, transmit, or otherwise make available any material that contains viruses or any other
          computer code, files or programs designed to harm, interfere or limit the normal operation or working & functioning of
          the Service (or any part thereof), or any other computer software or hardware;
          <br>
          * interfere with or disrupt the Group services;
          <br>
          * plan or engage in any illegal activity using the Groups;
          <br>
          * circulate or post any jokes and poems;
          <br>
          * circulate or post any religious or politically oriented views or comments; and
          <br>
          * pretend to be anyone, or any entity, you are not i.e. You may not impersonate or misrepresent yourself as another
          person (including celebrities), entity, another participant of the Groups, an employee or official or representative of
          Finbingo
        </p>
        <p>
          The Group Admin reserves the right at all times to determine, in its sole discretion, whether the content posted on the
          Group is appropriate and in compliance with these Terms of Use. The Group Admin further reserves the rights to remove
          any participant/member from the Group if it finds You or any participant/member to have circulated/posted any content
          which is objectionable or improper for the Groups or its participants and/or deemed to have violated the Terms of Use or
          for any reason whatsoever. The participants shall not challenge or question such removal by the Group Admin or
          declaration of any content as inappropriate or not compliant with the Terms of Use. The participant or member hereby
          agrees, admits and acknowledges that any of such actions by the Groups Admin shall not be construed or interpret as
          defamatory by the participant, member or You. This Group shall be used only for communication and group chat in relation
          to financial investments, and financial products.
        </p>
        <p>
          The views or comments posted herein by any participant (including any recommendation for investment and/or disinvestment
          in any financial products including mutual funds) shall be the views and comments of that participant only and shall not
          be construed to be the views or comments of the Group Admin and/or the Company, Finbingo or that such view/comments are
          endorsed by the Group Admin and/or the Company or Finbingo.
        </p>
        <p>
          The participant agrees and acknowledges that views/ comments/ suggestion by any other participants in the Groups shall
          be subject to verification by the participant reading/relying upon such views/ comment/ suggestion including view/
          comments/ suggestion with respect to any financial product or class of financial products and financial market
          conditions. The participant agrees and acknowledges that the investments in mutual funds, stocks, pension products or
          insurance policies or any other financial products are subject to market risks (including possible loss of the principal
          amount invested), and the participant shall read all investment/scheme-related documents carefully, and make its own
          assessment before making any investment/ disinvestment decision.
        </p>
        <p>
          You agree to defend, indemnify and hold the Group Admin, the Company and/or Finbingo, its affiliates, subsidiaries,
          directors, officers, employees, agents, partners and licensors harmless from any claim or demand, including reasonable
          attorney’s fees, made by a third party or any other participant in the Groups, relating to or arising from: (a) any
          content You submit, post, transmit, or otherwise make available in and through the Groups; (b) Your use of the Groups;
          and/ or (c) any violation by You of the Terms of Use. This obligation shall survive the termination or expiration of
          these Terms of Use or termination of your usage of the Groups by You and due to Your removal by the Group Admin.
        </p>
        <p>
          You agree and acknowledge that Your personal information including your mobile number disclosed in the Groups may be
          used by the participants and/or the Groups Admin/the Company or Finbingo to communicate or converse or establish contact
          with you or for any other purposes. You hereby further unconditionally consents that such communications via SMS/text
          messaging services, WhatsApp or other messenger services and/ or voice call by any other participant, Group Admin/
          Finbingo is (a) upon the request and/or authorization by you, (b) ‘transactional’ and not an ‘unsolicited commercial
          communication’ under applicable laws including under the guidelines issued by Telecom Regulation Authority of India
          (“TRAI”) and (c) in compliance with the relevant guidelines & regulation of TRAI or such other authority or sovereignty
          in India and abroad. You shall indemnify the Group Admin and/or Finbingo against all types of losses and damages
          incurred by it or its affiliates, ,subsidiaries, directors, officers, employees, agents, partners and licensors due to
          any action taken by TRAI, access providers (as per TRAI regulations) or any other authority due to any erroneous
          compliant raised by you or anyone on the Group Admin and/or Finbingo or its affiliates, subsidiaries, directors,
          officers, employees, agents, partners and licensors with respect to the intimations mentioned above or due to a wrong
          number or other contact details provided by you for any reason whatsoever.
        </p>
        <p>
          If you have provided us your contact information & details, such as email – id or phone/ telephone number, we may send
          you announcements, report, statement, messages, letters or information on our Services and how to use & avail them. You
          may opt out of some of these messages, announcement or letters using preferences on our website or mobile app.
        </p>
        <p><strong>Posts</strong></p>
        <p>
          The Company and/ or Finbingo may provide and display content on the Platform which features specific articles/write ups
          by third parties in relation to the mutual fund and stocks. You hereby agree and acknowledge that such content displayed
          on the Platform does not represent the views and/ or recommendations of the Company and/or Finbingo and You are required
          to read the documents carefully before investing in any mutual funds and/ or stocks. You may also be allowed to post and
          comment on such content on the Platform and You hereby undertake to ensure that such comments shall not be offensive and
          will be in accordance with applicable laws. All material added, created, submitted, or posted to the Platform by you is
          your sole responsibility. The Company and/ or Finbingo reserves the right to review any information provided/data
          uploaded, if any, by you on the Platform and delete any information/data that is inconsistent with these Terms of Use.
        </p>
        <p><strong>Finbingo Intellectual Property</strong>
        </p>
        <p>
          Finbingo owns all intellectual property rights in respect of the Services including any literature, reports, charts,
          documents, data, drawings, designs, diagrams, tables, software, source code or object code or other information or
          materials, howsoever stored or held, acquired, created, developed, designed or in any way prepared, by Finbingo.
        </p>
        <p>
          The “Finbingo” name and logos and all related names, trademarks, service marks, design marks, and slogans are the
          trademarks or service marks of SSBA Innovations Limited.
        </p>
        <p>
          You may not use the Finbingo logo in any manner that is deceptive or disparaging, or in connection with any product or
          service that Finbingo does not explicitly endorse. If you wish to use Finbingo’s trademarks, please contact
          info@Finbingo.com
        </p>
        <p>
          <strong>Disclaimer of warranties, indemnification & limitation of liabilities</strong>
        </p>
        <p><strong>No Liability</strong></p>
        <p>
          The Company will have no liability or responsibility related to any user content arising under intellectual property
          rights, libel, privacy, publicity, obscenity or other laws or regulation. The Company also disclaims all responsibility,
          liability & accountability with respect to the change, misuse, loss, modification, update or unavailability of any user
          Content or data. Further, the Company will not be liable for any loss that you may incur as a consequence of
          unauthorized use of your Account or Account information in connection with the Platform or the Platform Services either
          with or without your knowledge. You agree that Finbingo/ its authorized representatives’/service providers, Mutual
          Funds, Asset Management Companies and their officers, advisors and agents are not liable & not responsible for any loss
          & marked down caused through a fall or drop in value of investments / NAV etc which can go up or down depending on the
          elements, factors and forces affecting capital & money markets or any indirect, special or consequential loss investor
          or you might suffer.
        </p>
        <p>
          Further, you will not dispute or hold Finbingo responsible for:
        </p>
        <p>
          a. any act that is not an obligation of Finbingo under the Terms of Use;
          <br>
          b. any disclosures made by Finbingo to any statutory body under any law;
          <br>
          c. any loss, notional or otherwise, incurred by you due to delays either at the bank, BSE Star mutual fund, registrar
          and transfer agency and/or the AMC;
          <br>
          d. rejection of your instructions by the bank, BSE Star MF, registrar and transfer agency and/or AMC; and
          <br>
          e. Operating & processing of instructions authenticated by your login credentials, non-availability or non-accessibility
          of the Platform, telephone(s), or office(s) of Finbingo in case of circumstances beyond Finbingo’s control.
        </p>
        <p>
          <strong>
            Other warranties
          </strong>
        </p>
        <p>
          You expressly understand and agree that, to the maximum extent permitted by applicable law, the Platform and other
          Content are provided by the Company on an “as is” basis without warranty of any express, implied, statutory, kind or
          otherwise, including the implied warranties of title, non-infringement, merchantability , marketability or fitness for a
          particular purpose. Without limiting the preceding or foregoing, the Company makes no warranty that (i) the Platform
          (Finbingo.com) or Platform Services will meet your requirements or demands or your use of the Platform will be timely,
          uninterrupted, secure or error-free; (ii) the results that may be obtained from the use of the Platform will be
          effective, accurate or reliable; (iii) any errors or defects in the Platform will be corrected. No advice or
          information, whether oral or written, obtained by You from the Company and/or Finbingo shall create any warranty not
          expressly stated in the Terms of Use.
        </p>
        <p>
          <strong>User Warranty</strong>
        </p>
        <p>
          You warrant that all the details and information provided by You to the Company and/or Finbingo while using this
          Platform (including for the purposes of carrying out investments) are correct, accurate and genuine. You further warrant
          that providing such details and information on the Platform or sharing of the aforesaid details/information shall not
          violate any third – party rights or the intellectual property right of any third party.
        </p>
        <p>
          <strong>
            Other Important Terms and Conditions
          </strong>
        </p>
        <p>
          <strong>Confidentiality</strong>
        </p>
        <p>
          Finbingo shall make reasonable efforts to keep all the information relating your dealings through Finbingo Account
          confidential. Provided however that the Finbingo shall be entitled to disclose any information or particulars pertaining
          to you to any authority, statutory or otherwise as may be required by law or third party service provider required to
          affect the transactions requested by you.
        </p>
        <p><strong>Your Privacy and Data Protection</strong></p>
        <p>
          Finbingo’s Privacy Policy (available at: https://www.Finbingo.com/privacy-policy/) explains how we act & treat your
          personal data & information and protect/ secure your privacy when you use/ utilize our Services. By using our Services,
          you agree that Finbingo can use such data & information in accordance with our Privacy Policy.
        </p>
        <p>
          <strong>
            Violation of Terms of Use
          </strong>
        </p>
        <p>
          You agree & acknowledge that any violation or breach by you of these Terms of Use will constitute an unlawful, illegal
          and unfair business practice, and will cause irreparable harm & damage to the Company and/ or Finbingo and/or
          finbingo.com, as the case may be, for which monetary damages would be inadequate, and you consent to the Company
          obtaining any equitable or injunctive relief that they consider / deem necessary or appropriate in such circumstances.
          These remedies & treatment are in addition & over and above to any other remedies that the Company may have at law or in
          equity. If the Company takes any legal action against you as a result of your violation of these Terms of Use, they will
          be entitled to recover from you, and you agree & accept to pay, all reasonable attorneys’ fees, expenses and costs of
          such action, in addition to any other relief that may be granted.
        </p>
        <p>
          <strong>Suspension & Termination</strong>
        </p>
        <p>
          The Terms of Use will continue to apply until terminated by either the company or you as set forth below. If you object
          or disagree to the Terms of Use or are dissatisfied with the Platform, your only recourse is to (i) close & deactivate
          Your Account on the Platform; and/or (ii) stop accessing & using the Platform. In case you violate these Terms of Use
          and/ or any applicable law, the Company may, at any time and in its sole discretion, terminate Your Account and/or
          prevent You from accessing the Platform and/or Platform Services.
        </p>
        <p>
          The Company may delist you or block your future access to the Platform or suspend or terminate your Account if it
          believes, in its sole and absolute discretion that you have, breached, infringed, violated, abused, or unethically
          manipulated or exploited any term of these Terms of Use or anyway otherwise acted unethically. Notwithstanding anything
          in this clause, these Terms of Use will survive & persist indefinitely unless and until the Company and/or Finbingo
          chooses to discontinue or terminate them. If You or the Company terminate your use of the Platform, the Company may
          delete any Content or other materials relating to Your and the Company shall have no liability & accountability to you
          or any third party for doing so. However, your transactions & settlement details may be preserved & retained by the
          Company for purposes of regulatory compliance or tax/ taxes. The Company, in consultation with its legal team, may
          modify these terms at any time.
        </p>
        <p>
          i. The Finbingo facility / services may at any time be suspended / disallowed /modified for such reason and for such
          time period as deem & considered fit like inactive / dormant user id / client, noncompliance of the terms & condition
          stated herein, death, insolvency, bankruptcy of the investor, any other cause arising out of the operation of law or
          such other reason as deems proper.
          <br>
          ii. Finbingo facility may be terminated by giving you 30 days intimation/notice to your registered e-mail id or by way
          of SMS to your registered phone number. You may terminate the dealings at any time by writing to Finbingo or
          deactivating your user id/ ids and password. In either case, you are required to give or provide a prior written notice
          of 15 (Fifteen) days. In the event of termination for any reason whatsoever, you shall be liable & responsible to pay
          all outstanding charges, fees and dues, if any.
          <br>
          iii. In case you cease to be a client of Finbingo then all your details will be provided to the Mutual Fund/AMCs and as
          are required by them to enable you to deal and interact or communicate directly with the AMCs/Mutual Fund
        </p>
        <p>
          <strong>Governing Laws</strong>
        </p>
        <p>
          The Terms of Use shall be governed and construed in accordance with the laws of India without reference to conflict of
          laws principles. All disputes arising in relation to shall be subject to the exclusive jurisdiction of court at Mumbai.
          <br>
          In case of any debate or dispute or argument, either judicial or quasi-judicial the same will be subject to the
          exclusive jurisdiction of the courts in Mumbai. These terms shall be construed in accordance with the laws prevalent in
          India and are subject to SEBI guidelines, circulars, press releases or notifications that may be issued by SEBI from
          time to time or the Government of India, to regulate the activities of RIAs (Registered Investment Advisors).
        </p>
        <p><strong>Notice</strong></p>
        <p>
          By hand, delivered by courier, Delivery by post, By registered post, Under certificate of posting, By email or fax, By
          affixing it on the door at the last known business or residential address, By oral communication to the party or on the
          last known telephone number or on the recording machine of such number, By notice posted on the notice board of the
          Exchange if no communication address or mode is known. Any communication sent by Finbingo to the client or you shall be
          deemed to have been properly delivered or served, if such communication is returned on Finbingo as unclaimed / refused /
          undelivered, if the same was sent in any one more of the above modes of communication to the ordinary location and place
          of residence and / or last known web address /residing address and / or at the ordinary business/ work address of the
          party to this agreement.
        </p>
        <p><strong>Feedback and Complaint</strong></p>
        <p>
          You agree to provide honest feedback/ review about Finbingo platform. In case of any dissatisfaction with the Platform
          or Services, you shall first file a formal complaint with the customer service of the Company and/or Finbingo, as may be
          applicable, prior to pursuing any other recourse. The complaints can be lodged at support@Finbingo.com and upon lodging
          a complaint you agree to provide complete support to the customer service team with such reasonable and sufficient
          information as may be sought by them from you to resolve your complaint. The decision of the company and/ or Finbingo,
          as may be applicable, on the complaints shall be final and you agree to be bound by the same.
        </p>
        <p>
          <strong>Modifying and Terminating Our Services</strong>
        </p>
        <p>
          We are constantly changing and improving our Services. We may remove or add functionalities, utilities or features, and
          we may stop or cease a Service altogether. In each case, we are not bound to inform individual users of these changes. <br>You can/ may stop accessing and using our Services at any time. Company and / or Finbingo may also stop providing
          Services to you, or create new limits and boundaries to our Services at any time.
        </p>
        <p>
          <strong>About these Terms</strong>
        </p>
        <p>
          These Terms govern & regulate the relationship & association between Finbingo and you / client. They do not create or
          obtain any third party beneficiary rights. <br>
          If you do not comply or agree, understand or acknowledged with these Terms, and we do not take action immediately, it
          will not be construed as our consent or approval to your non-compliance.
          <br>
          If any term or condition contained in these Terms is unenforceable, it will not affect the other Terms or terms of use.
          <br>
          You agree & accept that the laws, legislation and jurisprudence of India will apply to any dispute arising out of or in
          relation to these Terms or the Services.
        </p>
        <p>
          <strong>SEBI Caution</strong>
        </p>
        <p>
          Mutual fund investments are subject to market risks, read all scheme-related documents (SID, KIM etc) carefully. Past
          performance (returns & ratio) is not an indicator of future returns & risk.
        </p>
        <p>
          <strong>General Provisions</strong>
        </p>
        <p>
          <strong>
            Assignment
          </strong>
        </p>
        <p>
          Assignment: You cannot assign or otherwise transfer the Terms of Use, or any rights granted hereunder to any third The
          Company’s rights under the Terms of Use are freely transferable by the Company or / and Finbingo to any third party
          without the requirement of seeking Your acceptance or consent.
        </p>
        <p>
          <strong>Severability</strong>
        </p>
        <p>
          If, for any cause or reason, a court or bar or court bench of competent jurisdiction finds any provision or part of the
          Terms of Use, or any portion thereof, to be unenforceable, that provision shall be applied & enforced to the maximum
          extent permissible & allowable so as to give effect to the intent of the parties as reflected by that provision, and the
          remainder of the Terms of Use & conditions shall continue in full force and effect.
          <br>
          In the event of any one or more of the provisions contained in this Agreement or contract becoming invalid / void,
          illegal or unenforceable in any respect under any law or court for the time being in force, the validity, legality and
          enforceability of the remaining provisions & terms contained herein shall not in any way be prejudiced, distorted or
          affected thereto.
        </p>
        <p><strong>Waiver</strong></p>
        <p>
          Any failure by the Company or / and by the Finbingo to enforce or exercise or apply any provision & part of the Terms of
          Use, or any related right, shall not constitute a waiver by the Company of that right or provision.
        </p>
      </div>
    </div>
  </div>
</section>