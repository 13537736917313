import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  httpOptions;
  httpCallOptions;

  // For Normal API
  setNormalHeaders() {
    return this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }),
      responseType: 'json',
    };
  }

  // Get-data API
  getAPICall(serviceEndpoint, apiEndpoint): Observable<any> {
    this.httpCallOptions = {
      headers: this.setNormalHeaders()
    };
    return this.http.get(serviceEndpoint + apiEndpoint, this.httpCallOptions).pipe((resData) => resData);
  }

  // Post-data API
  postAPICall(serviceEndpoint, apiEndpoint, jsonBody): Observable<any> {
    this.httpCallOptions = {
      headers: this.setNormalHeaders()
    };
    return this.http.post(
      serviceEndpoint + apiEndpoint,
      jsonBody,
      this.httpCallOptions
    ).pipe((resData) => resData);
  }

  // ---SessionStorageModel---

  sessionStorageModel: SessionStorageModel = new SessionStorageModel();

  public setStorage(key: string, value: string) {
    this.sessionStorageModel[key] = value;
  }
  getStorage(key: string): string {
    return this.sessionStorageModel[key]
  }
  removeStorage(key: string) {
    this.sessionStorageModel[key] = null;
  }
  clearStorage() {
    this.sessionStorageModel = new SessionStorageModel();
  }
}

export class SessionStorageModel {

  //mfRecommendation
  mfRecommendation: any;
}