<section class="homepage-blocks pb-0">
  <div class="container finbWbBanner">
    <div class="row text-center justify-content-center mb-5">
      <br>
      <br>
      <div class="col-lg-10 col-xl-9">
        <!-- <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/NEW-MAIN-IMG3.jpg" width="100%" alt=""> -->
        <h1 class="page-bigtitle">Financial Life Goals</h1>
        <h1 class="page-bigtitle orange">Your dreams are within your reach now</h1>
        <p>
          <strong>Our intelligent robo-advisor is waiting to begin assisting you with your goals.</strong> <br>
          <strong>Simply set your goals and allow the AI-driven tool to start helping you reach them.</strong> <br>
        </p>

        <div class="text-center startedBtn">
          <a [href]="cmService.portalURL + '/grow-wealth/'" class="btn btn-orangehomepage">Get Started</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks pt-0">
  <div class="container">
    <div class="why-finbingo-wb finbWhitebox">
      <div class="finb-feature-content">
        <div class="sound-financiallife">
          <div>
            <p>
              Whether it’s for your retirement, further education, a dream vacation (or two), marriage, a home, a car, kids’ education, building long-term wealth, or creating a sustainable income stream, our robo-advisor will help you get there.
            </p>
            <p>
              No matter what your goal or how unattainable it appears today, just tell us your funds requirement and when you think
              you’ll need them. We will crunch the numbers and tell you by when you will be able to reach those goals.
            </p>
            <p>
              <strong>How it works</strong>
              <br>
              We will require you to enter all your financial data. This includes assets, liabilities, insurance and pension plans, income, expenses, etc.
            </p>
          </div>
          <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/financial-goals-advisor-finbingo.png" width="100%" alt="Set Your Goals" title="Set Your Goals">
        </div>
        <p>
          Wealth Builder uses this as the base to create financial data points to assess the feasibility of your life goals.
        </p>
        <br>
        <br>
        <br>
        <br>
        <p>
          Here are some of the goals that people start to work on:
        </p>
        <p>
          <strong>• A safety net</strong>
          One of the first goals to start working on is to put aside money in an emergency fund for life’s unplanned bumps.
        </p>
        <p>
          <strong>• Your retirement</strong>
          It might seem a long way off today, but a retirement is the one of the biggest truths for all of us. Our intelligent
          robo-advisor will help you save for your retirement at the time in the future that you envision. The robo-advisor takes
          care of inflation, your spending habits and needs at the time of retirement to come up with a retirement-ready strategy.
        </p>
        <p>
          <strong>• Building a corpus</strong>
          Rich people have money, while wealthy ones have time. If you wish to build a corpus over time for a future that is uncertain or just to be able to make plans on the fly, then you must consider this as one of your financial goals.
        </p>
        <p>
          <strong>• Planning for a child</strong>
          Begin saving for school and college, select life insurance and forecast your future needs.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks">
  <div class="container">
    <div class="finb-mygoals">
      <h4>Set your own Financial Life Goals</h4>
      <p>
        <strong>Everybody has a dream.</strong>
        <br>
        Whether it’s planning for retirement, further education, marriage, purchasing a home, a car, funding a child’s
        education or simply creating a sustainable income stream, our robo-advisor will help you fulfill your financial
        goals.
      </p>
    </div>
    <div class="finb-mygoals-flex">
      <div>
        <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/insurance-11.jpg" alt="Dream Car" title="Dream Car">
        <h5>Your Dream Car</h5>
        <p>
          Walk into the showroom and drive out in your own car. This need not be a far-off dream, but it can easily be
          reality. All you need to do is plan for it a bit.
          We’ll show you how.
        </p>
      </div>
      <div>
        <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/insurance-09.jpg" alt="Marriage" title="Marriage">
        <h5>Marriage</h5>
        <p>
          A wedding is an occasion in everyone’s life, where people wish to pull out all stops. With a little foresight
          and planning, it is possible to have the dream wedding you’ve always wanted.
        </p>
      </div>
      <div>
        <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/insurance-10.jpg" alt="Children Education" title="Children Education">
        <h5>Children's Education</h5>
        <p>
          Whatever your child desires to become – a doctor, an astronaut, a filmmaker, an artist, an engineer or a
          lawyer – you can be ready to give wings to their dreams.
        </p>
      </div>
    </div>
  </div>
</section>


<section class="homepage-blocks">
  <div class="container">
    <div class="finb-mygoals">
      <h4>Get Started Today!</h4>
      <br>
      <p>
        <strong>The best time to begin investing in your financial future was yesterday; the next best is
          today!</strong>
      </p>
      <p>
        Achieve your life goals by proper planning using Finbingo’s goal planner
      </p>
      <div class="text-center">
        <a [href]="cmService.portalURL + '/grow-wealth/'" class="btn btn-orangehomepage">Get Started</a>
      </div>
    </div>
  </div>
</section>
<br>
<br>
<br>