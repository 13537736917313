<section class="homepage-blocks pb-0">
  <div class="container finbWbBanner">
    <div class="row text-center justify-content-center mb-5">
      <br>
      <br>
      <div class="col-lg-10 col-xl-9">
        <h1 class="page-bigtitle">Finbingo Financial & Tax Calculators</h1>
        <p>
          Use our financial calculators to fine-tune your investment planning, compare tax liabilities for different tax regimes,
          evaluate Financial Life goals, or calculate your Human Life Value. <br>
        </p>
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-11" (click)="redirectToCal('SIP')">
        <div class="right-left-block">
          <div class="finb-feature-content">
            <h4>SIP Calculator</h4>
            <p>
              A Systematic Investment Plan (SIP) helps you to make small investments in Mutual Funds at regular intervals
            </p>
            <p class="">
              To use this calculator, you will need to tell us how much you plan to start investing in SIP and we will tell you, how
              much your money would have grown.
            </p>
          </div>
          <div class="finb-featureimg">
            <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/SIP-Calculator-1.png" alt="SIP" title="SIP">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="homepage-blocks bg-light-gray">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-11" (click)="redirectToCal('GOAL-SIP')">
        <div class="left-right-block">
          <div class="finb-featureimg">
            <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/finbingo-mutual-funds-platf.png" alt="Goal SIP" title="Goal SIP">
          </div>
          <div class="finb-feature-content">
            <h4>Goal SIP Calculator</h4>
            <p>
              Find out how you can achieve your Financial Life Goals by investing in Systematic Investment Plan (SIP).
            </p>
            <p>
              Provide us the future value of your Goal &year in which you wish to achieve the financial life goal. We will tell you the exact amount you need to start investing in SIP.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="homepage-blocks">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-11" (click)="redirectToCal('HLV')">
        <div class="right-left-block">
          <div class="finb-feature-content">
            <h4>Human Life Value Calculator</h4>
            <p>
              The Human Life Value (HLV) Calculator helps you identify your life insurance needs.
            </p>
            <p>
              To calculate your insurance needs you need to provide us your age, retirement age,income, any liabilities. Finbingo will suggest you an ideal life cover till Retirement.
            </p>
          </div>
          <div class="finb-featureimg">
            <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/Insurance-Calculators1.png" alt="Calculator" title="Calculator">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="homepage-blocks bg-light-gray">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-11" (click)="redirectToCal('NEW-BUDGET-TAX-CAL')">
        <div class="left-right-block">
          <div class="finb-featureimg">
            <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/Budget2020.png" alt="Tax Calculator" title="Tax Calculator">
          </div>
          <div class="finb-feature-content">
            <h4>New Budget Tax Calculator <br>(F.Y.2022-23)</h4>
            <p>
              New Budget Tax Calculator (F.Y.2022-23) is an easy-to-use online tool that helps you estimate your taxes based on your income after the Union Budget 2020 is presented.
            </p>
            <p>
              This calculator takes into account the new changes to give you comparison of your tax liability for old and new tax regimes and help you choose the right option.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="homepage-blocks">
  <div class="container">
    <div class="finb-mygoals">
      <h4>Get Started Today!</h4>
      <br>
      <p>
        <strong>The best time to begin investing in your financial future was yesterday; the next best is
          today!</strong>
      </p>
      <p>
        Take calculated decision using Finbingo’s Financial calculators
      </p>
      <div class="text-center">
        <a [href]="cmService.portalURL + '/calculator/'" class="btn btn-orangehomepage">Get Started</a>
      </div>
    </div>
  </div>
</section>
<br>
<br>
<br>