<section class="manager-descriptionwrap">
  <div class="container">
    <div class="manager-img">
      <img
        src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/srinivas-reddy-finbingo.jpg"
        alt="Srinivas Reddy" title="Srinivas Reddy">
    </div>
    <h4>Srinivas Reddy</h4>
    <h5>CTO</h5>
    <div class="manager-social">
      <a href="https://www.linkedin.com/in/srinivasventure/" target="_blank">
        <i class="fab fa-linkedin"></i>
      </a>
      <!-- <a href="javascript:void(0);">
        <i class="fab fa-twitter-square"></i>
      </a>
      <a href="javascript:void(0);">
        <i class="fab fa-instagram"></i>
      </a> -->
      <a href="https://www.facebook.com/srinivas.reddy3" target="_blank">
        <i class="fab fa-facebook-square"></i>
      </a>
    </div>
    <div class="manager-description">
      <p>
        Srinivas holds a Bachelor of Computer Science degree from Fergusson College, Pune and has completed his Masters
        in
        Computer Science from Interdisciplinary School of Scientific Computing, Pune University.
      </p>
      <p>
        Srinivas’ interests have enabled him to garner varied professional experience spanning almost 2 decades across
        geographies, with a focus on Asia.
      </p>
      <p>
        In his career earlier he has been the Cofounder and Chief Technology Officer at EventValue, Bangalore, where his
        key
        responsibilities were to solve the pain points of professional events, conferences, and exhibitions. Prior to
        that
        Srinivas played a significant role in ISI Emerging Markets as their Global Projects and Asia Technology Manager,
        responsible for leading diverse teams. It was under his aegis that the company was able to accomplish the
        biggest
        acquisitions in Hong Kong, leading to the setting up of a delivery centre in Bangalore.
      </p>
      <p>
        Srinivas is a technology evangelist with keen interest in consumer technology innovation using web and mobile
        apps. He
        has spent considerable years in start-ups, assisting them in defining technology roadmaps, architecting
        solutions,
        building relevant teams and streamlining operations.
      </p>
      <p>
        An open source enthusiast, Srinivas was one of the early adopters and advocates of open source. He continues to
        experiment around open source technologies and its impact in the post-cloud era. He likes to keep himself
        abreast with
        cutting-edge technologies and at times even gets his hands dirty on weekend prototypes.
      </p>
      <p>
        There is a blurred line between Srinivas’ professional and personal identities. He is an avid technology reader
        and
        loves to spend time on technology trends and doing hands-on and proof of concepts.
      </p>
      <a href="javascript:void(0);" routerLink="/team-finbingo" class="btn btn-primary">Back to Team Finbingo</a>
      <br>
      <br>
    </div>
  </div>
</section>