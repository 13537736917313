<section class="pt-5 pb-0">
    <div class="container finbWbBanner">
        <div class="row text-center justify-content-center">
            <div class="col-lg-10">
                <h1 class="page-bigtitle">Grievance</h1>
            </div>
        </div>
    </div>
</section>
<section class="homepage-blocks newhomepage ">
    <div class="container">
        <p>At SSBA Innovations Limited, we believe that Client service is an essential element for the
            growth of the Company, and we want to provide the best Client’s experience while using our products,
            service, and information. To make sure that the company's grievance redressal process is more
            significant and valuable , this system has been set up to analyze and solve all customer grievances in
            real-time and take suitable action to avoid their occurrence in the future.</p>
        <p>We try to ensure that the redressal process aims to be helpful and permissible within the given framework
            of rules and regulation.</p>
        <p>Grievance Redressal Mechanism
        </p>
        <p class="mb-4">Clients are required to approach the Company directly to register a complaint through any of
            the
            touch points mentioned below, and in the normal course can expect a response within 2 business days of
            raising the issue.</p>
        <h5 class="mt-2 mb-2">Level 1</h5>
        <p class="mb-4">If a Client has any grievances/complaints, the Client can send an email on <a
                href="mailto:care@finbingo.com">care@finbingo.com</a></p>
        <h5 class="mb-2">Level 2</h5>
        <p class="mb-3">If the Client’s issue is unresolved after a period of 7 days from the date of first raising
            the issue at
            Level 1 or if the Client is not satisfied with the response provided at Level 1, the Client may, post
            completion of a 7-day period from the date of first raising the issue at Level 1, write to the
            Compliance/Grievance Officer on <a
                href="mailto:grievance@ssbainnovations.com">grievance@ssbainnovations.com</a></p>
        <p class="mb-4">Note: If any query/ complaint requires additional time, the Company will inform the Client
            the reason for
            the delay and provide expected timelines for resolution of the issue raised.</p>
        <h5 class="mb-2">Level 3
        </h5>
        <p class="mb-4">If the Client’s issue is unresolved for a period of 7 days after the Client reaches out to
            the
            Compliance/Grievance Officer as provided in Level 2, the Client may write to the Head Office of Finbingo
            at <a href="mailto:grievance@ssbainnovations.com">grievance@ssbainnovations.com</a></p>
        <h5 class="mb-2">Level 4</h5>
        <p class="mb-4">If the Complaint is not resolved at Level 3 within a period of 7 days from the date of such
            issues first
            being raised at Level 3, or if the client is not satisfied with the company’s grievance redressal, the
            client may, after completion of 7 days after the issue was first raised at Level 3, approach SEBI at
            <a id="link" href="https://www.scores.gov.in/scores/Welcome.html"
                target="_blank">https://www.scores.gov.in/scores/Welcome.html
            </a>
        </p>
        <h5 class="mb-2">Level 5</h5>
        <p class="mb-4">If the Complaint is not resolved at Level 4 within a period of 7 days from the date of such
            issues first being raised at Level 4, or if the client is not satisfied with the company’s grievance
            redressal, the client may, after completion of 7 days after the issue was first raised at Level 5, approach
            SEBI at
            <a id="link" href="https://smartodr.in/login" target="_blank">https://smartodr.in/login
            </a>
        </p>
        <h4 class="mb-3">Compliments</h4>
        <p>If you had a good experience with us and if you like our service and products you can share with us at <a
                href="mailto:care@finbingo.com">care@finbingo.com</a>.
        </p>
        <p>Thank you for your encouragement! We will always try to give our best and seamless service.</p>
    </div>
</section>