import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhatIsFinbingoComponent } from './what-is-finbingo/what-is-finbingo.component';
import { OurStoryComponent } from './our-story/our-story.component';
import { TeamFinbingoComponent } from './team-finbingo/team-finbingo.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SamirJayaswalComponent } from './team-finbingo/samir-jayaswal/samir-jayaswal.component';
import { AtulRegeComponent } from './team-finbingo/atul-rege/atul-rege.component';
import { SanjayGodboleComponent } from './team-finbingo/sanjay-godbole/sanjay-godbole.component';
import { SrinivasReddyComponent } from './team-finbingo/srinivas-reddy/srinivas-reddy.component';
import { SujitBangarComponent } from './team-finbingo/sujit-bangar/sujit-bangar.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VidyasagarPatilComponent } from './team-finbingo/vidyasagar-patil/vidyasagar-patil.component';

@NgModule({
  declarations: [WhatIsFinbingoComponent, OurStoryComponent, TeamFinbingoComponent, ContactUsComponent,SamirJayaswalComponent,AtulRegeComponent,SanjayGodboleComponent,SrinivasReddyComponent,SujitBangarComponent, VidyasagarPatilComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class AboutModule { }
