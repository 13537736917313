import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private title: Title, private meta: Meta) { }

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url })
    this.meta.updateTag({ name: 'twitter:url', content: url })
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
    this.meta.updateTag({ property: 'og:title', content: desc })
    this.meta.updateTag({ property: 'og:description', content: desc })
    this.meta.updateTag({ name: 'twitter:title', content: desc })
    this.meta.updateTag({ name: 'twitter:description', content: desc })
  }

  updateKeywords(keywords: string) {
    this.meta.updateTag({ name: 'keywords', content: keywords })
  }

  updateOtherDesc(url) {
    // Facebook Open Graph
    this.meta.updateTag({ property: 'og:locale', content: 'en_US' });
    this.meta.updateTag({ property: 'og:site_name', content: 'Finbingo' });
    this.meta.updateTag({ property: 'og:type', content: url == '/' ? 'website' : 'article' });
    this.meta.updateTag({ property: "og:image", content: "https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/logo-white.png" });
    this.meta.updateTag({ property: "og:image:url", content: "https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/logo-white.png" });
    this.meta.updateTag({ property: "og:image:secure_url", content: "https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/logo-white.png" });
    this.meta.updateTag({ property: "article:publisher", content: "https://www.facebook.com/FinBingo" });

    // Twitter Cards
    this.meta.updateTag({ name: "twitter:image", content: "https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/logo-white.png" });
    this.meta.updateTag({ name: "twitter:card", content: "summary_large_image" });
    this.meta.updateTag({ name: "twitter:site", content: "@myfinbingo" });
  }
}
