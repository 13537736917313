<section class="homepage-blocks">
  <div class="container finbWbBanner">
    <div class="row text-center justify-content-center">
      <div class="col-lg-10 col-xl-8">
        <h1 class="page-bigtitle">Portfolio Doctor</h1>
        <h1 class="page-bigtitle orange">Get health back into your wealth</h1>
        <p><strong>Why stay in uncertainty</strong></p>
        <p>
          With Portfolio Doctor, you can pinpoint the poor funds in your investment basket and act in time.
        </p>

        <div class="text-center">
          <a [href]="cmService.portalURL + '/InvestNow/mfPortDocAnalysis'" class="btn btn-orangehomepage">Get Started</a>
        </div>

      </div>
    </div>
  </div>
</section>
<section class="homepage-blocks">
  <div class="container">
    <div class="finb-wbtpa-module">
      <div>
        <h5>Why Portfolio Doctor</h5>
        <p>People keep investing in different mutual funds without realizing that most of them are not suited to their needs.</p>
      </div>
      <div>
        <h5>How it works</h5>
        <p>Simply share your CAS with us to do detailed fund level and portfolio level analysis.</p>
      </div>
      <div>
        <h5>What you get</h5>
        <p>You can expect unbiased analysis of your portfolio every month, so you can add health back into your wealth.</p>
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks">
  <div class="container">
    <div class="why-finbingo-wb finbWhitebox">
      <div class="finb-feature-content">
        <h4>Why Portfolio Doctor?</h4>
        <p>
          We take so many steps to remain healthy. We try various diets, walk, run, exercise and count every calorie that we
          consume. Why don’t we display the same seriousness when it comes to monitoring our investments? We make an investment
          and lose sight of it quickly. We don’t even know if a fund should be there at all in our portfolio 6 months after buying
          it. Advisors tell you what to buy for their commission, but they never tell you what to sell.
        </p>
        <p>
          <strong>How we solve it</strong>
          <br>
          After analysing, 100s of portfolios, we realized that 70% of the money in your portfolio is not working for you. They
          just stop performing. We pass every fund through our own unbiased performance criteria and do this analysis everyday. As a result, we make your money work hard for you.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks">
  <div class="container">
    <div class="why-finbingo-wb finbWhitebox">
      <div class="finb-feature-content">
        <h4>How it works?</h4>

        <div class="how-works-timeline pd-timeline">
          <div class="hw-timeline-content">
            <div class="hw-timeline-number yellow-circle">
              <div class="hw-timeline-circle">
                <p>1</p>
              </div>
            </div>
            <div class="hw-timeline-text">
              <div class="hw-text">
                <div class="">
                  <h5><span>Provide your PAN and Email through which you made investments</span></h5>
                </div>
              </div>
            </div>
          </div>
          <div class="hw-timeline-content">
            <div class="hw-timeline-number green-circle">
              <div class="hw-timeline-circle">
                <p>2</p>
              </div>
            </div>
            <div class="hw-timeline-text">
              <div class="hw-text">
                <div class="">
                  <h5 class=""><span>Forward your CAS to cas@finbingo.com</span></h5>
                </div>
              </div>
            </div>
          </div>
          <div class="hw-timeline-content">
            <div class="hw-timeline-number orange-circle">
              <div class="hw-timeline-circle">
                <p>3</p>
              </div>
            </div>
            <div class="hw-timeline-text">
              <div class="hw-text">
                <div class="">
                  <h5 class=""><span>View / Download your customized report</span></h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p>
          Your CAS contains all your mutual fund investments records which is used by Portfolio Doctor for analysis. Portfolio Doctor analysis your investments at two levels: <br>
          That is what allows us to offer customized and nuanced financial services to every individual. However, the way we begin is the same for all.
        </p>
        <p><strong>At Fund Level, each fund is compared to their peers for</strong></p>
        <ul>
          <li>Past Performance</li>
          <li>Expense Ratio</li>
          <li>Fund Manager’s Performance</li>
          <li>Asset under Management</li>
          <li>Critical Assets</li>
          <li>Diversification</li>
        </ul>
        <p><strong>At Portfolio level, we take a holistic view based on</strong></p>
        <ul>
          <li>Overall Asset Quality</li>
          <li>Overall Risk Allocation compared to your Risk Appetite</li>
          <li>Overall Portfolio Performance</li>
          <li>Detailed Debt Fund Analysis</li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks">
  <div class="container">
    <div class="finb-mygoals">
      <h4>Get Started Today!</h4>
      <br>
      <p>
        <strong>The best time to begin investing in your financial future was yesterday; the next best is
          today!</strong>
      </p>
      <p>
        We have a set of tools for analyzing your mutual fund investments and group them in Well-performing, Non-performing and Average performing mutual funds for you to take action.
      </p>
      <div class="text-center">
        <a [href]="cmService.portalURL + '/InvestNow/mfPortDocAnalysis'" class="btn btn-orangehomepage">Get Started</a>
      </div>
    </div>
  </div>
</section>
<br>
<br>
<br>