import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourcesComponent } from './resources/resources.component';
import { BlogComponent } from './blog/blog.component';



@NgModule({
  declarations: [ResourcesComponent, BlogComponent],
  imports: [
    CommonModule
  ]
})
export class LearnModule { }
