import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumbersOnlyDirective } from '../directives/numbers-only.directive';
import { CurrencyINRPipe } from '../pipes/currency-inr.pipe';
import { SanitizePipe } from '../pipes/sanitize.pipe';



@NgModule({
  declarations: [
    NumbersOnlyDirective,
    CurrencyINRPipe,
    SanitizePipe
  ],
  exports: [NumbersOnlyDirective,CurrencyINRPipe,SanitizePipe],
  imports: [
    CommonModule
  ]
})
export class SharedModule { }
