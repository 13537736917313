import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-is-finbingo',
  templateUrl: './what-is-finbingo.component.html',
  styleUrls: ['./what-is-finbingo.component.css']
})
export class WhatIsFinbingoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
