import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private dataService: DataService, public cmService: CommonService) {

  }
  lastMonthDate;
  ngOnInit(): void {
    // this.getRecommendatMf();

    // get last month date
    let date = new Date(), y = date.getFullYear(), m = date.getMonth();
    let possibleDate = new Date(y, m + 0, 0);
    this.lastMonthDate = this.cmService.ordinal_suffix_of(possibleDate.getDate()) + ' ' + `<span class="text-uppercase">${(possibleDate.toLocaleString('en-IN', { month: 'long' }))}</span>` + ' ' + possibleDate.getFullYear();
  }

  toggleData = {
    fundHouses: false
  }
  emailAddress = 'samir@ssbainnovations.com';
  commonObject: any = {};
  mfRecommendationArray: any = [];
  categories: any = [];
  activeAssetType;
  mfDataArr: any = [{
    assetType: "",
    schemes: []
  }];

  getRecommendatMf(groupCode?) {
    if (!groupCode) {
      this.commonObject['mfRecommendation'] = false;
      this.dataService.getAPICall(environment.MF_SERVICE, "v1/dashboard/mf-recommendations/category")
        .subscribe(result => {
          if (result.success) {
            result.data.groupCode = groupCode
            this.setData('mfRecommendation', result.data)
          } else {
            this.commonObject['mfRecommendation'] = null;
          }
        })
    }
    let hasData = this.mfDataArr.find(item => item.assetType == groupCode);
    if (hasData) {
      this.activeAssetType = groupCode;
      this.mfRecommendationArray = this.mfDataArr.find(item => item.assetType == groupCode).schemes
    } else {
      this.commonObject['mfRecommendation'] = false;
      this.dataService.getAPICall(environment.MF_SERVICE, "v1/dashboard/mf-recommendations/category" + (groupCode ? "?categoryGroupCode=" + groupCode : ''))
        .subscribe(result => {
          if (result.success) {
            // Asset categories
            this.categories = result.data.categories;

            // Asset categoryWiseSchemes
            this.activeAssetType = groupCode ? groupCode : this.categories[0].code;
            this.mfDataArr.push({
              assetType: this.activeAssetType,
              schemes: result.data.categoryWiseSchemes
            });
            this.mfRecommendationArray = this.mfDataArr.find(item => item.assetType == this.activeAssetType).schemes;

            if (this.mfRecommendationArray == 0) {
              this.commonObject['mfRecommendation'] = null;
            } else {
              this.commonObject['mfRecommendation'] = true;
            }
            // easy Picked MF
            // this.easyPickedMFArr = result.data.easyPicks;
            // sessionStorage.setItem('MF_EASYPICK', JSON.stringify(this.easyPickedMFArr))
          } else {
            this.commonObject['mfRecommendation'] = null;
          }
        })
    }
  }

  setData(type, data) {
    if (type == 'mfRecommendation') {

      // Asset categories
      this.categories = data.categories;

      // Asset categoryWiseSchemes
      this.activeAssetType = data.groupCode ? data.groupCode : this.categories[0].code;
      this.mfDataArr.push({
        assetType: this.activeAssetType,
        schemes: data.categoryWiseSchemes
      });
      this.mfRecommendationArray = this.mfDataArr.find(item => item.assetType == this.activeAssetType).schemes;

      if (this.mfRecommendationArray == 0) {
        this.commonObject['mfRecommendation'] = null;
      } else {
        this.commonObject['mfRecommendation'] = true;
      }
      // easy Picked MF
      // this.easyPickedMFArr = data.easyPicks;
      // sessionStorage.setItem('MF_EASYPICK', JSON.stringify(this.easyPickedMFArr))
      // this.apiService.setStorage("mfRecommendation", JSON.stringify(data))
    }
  }

}
