<section class="homepage-blocks">
  <div class="container finbWbBanner">
    <div class="row text-center justify-content-center mb-5">
      <div class="col-lg-10">
        <!-- <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/NEW-MAIN-IMG3.jpg" width="100%" alt=""> -->
        <h1 class="page-bigtitle">Finbingo Subscription</h1>
        <h1 class="page-bigtitle orange">Choose the right plan for you!</h1>
        <p>
          <strong>Building a healthy financial portfolio is just a step away.</strong>
        </p>
      </div>
    </div>
  </div>
  <div class="subscription-plans">
    <div class="container new-plans">
      <div class="row">
        <ng-container *ngIf="subPlans.length > 0; else elseTemplate">
          <ng-container *ngFor="let item of subPlans">
            <div class="col-lg-3 mb-4">
              <div class="plancard card">
                <div class="card-header" [class.freePlanHeader]="item.planCode == 'FREE_PLAN'">
                  <div class="plans-header">
                    <span>
                      <img *ngIf="item.planCode == 'FREE_PLAN'"
                        src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/price-free.png"
                        alt="">
                      <img *ngIf="item.planCode == 'TPA_PLAN'"
                        src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/price-tpa.png"
                        alt="">
                      <img *ngIf="item.planCode == 'WB_PLAN'"
                        src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/price-wb.png"
                        alt="">
                      <img *ngIf="item.planCode == 'PD_PLAN'"
                        src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/price-pd.png"
                        alt="">
                    </span>
                    <h5 class="card-title truncate text-center" [title]="item.planName">
                      {{item.planName}}
                    </h5>
                  </div>
                  <P class="text-center" *ngIf="item?.planDescription">
                    <span [innerHTML]="item.planDescription" class="mr-2"></span>
                  </P>
                  <div class="btn-wrap text-center my-3" *ngIf="item.planCode != 'FREE_PLAN'">
                    <a *ngIf="item.planCode != 'FREE_PLAN'" class="btn btn-orange"
                      [href]="cmService.portalURL + '/plan-subscription/buy-subscription?planCode=' + item.planCode">
                      Buy Now
                    </a>
                  </div>
                </div>
                <div class="card-body">
                  <div class="card-content">
                    <h5> You Get</h5>
                    <ul class="pricing-ul">
                      <li *ngFor="let item2 of item.planOfferings">
                        <i class="fas fa-check-circle"></i>{{item2}}
                      </li>
                    </ul>
                    <div>
                    </div>
                  </div>
                  <div class="text-center" *ngIf="item?.planCode != 'FREE_PLAN'">
                    <button type="button" class="btn btn-orange" data-toggle="modal" (click)="open(item)"
                      data-target="#myModal">
                      View Demo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #elseTemplate>
          <div class="col-12 card my-3 text-center no-data">
            <div class="">
              <h5> Please wait loading data...!</h5>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</section>

<!-- The Modal -->
<div class="modal" data-keyboard="false" data-backdrop="static" id="myModal"
  [ngStyle]="{'display': showModalBox ? 'block' : 'none', 'opacity': 1}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">{{modalItem?.planName}}</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="closeModal()">&times;</button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <ng-container *ngIf="modalItem != null; else elseTemplate2">
          <div class="sneakpeak-container">
            <iframe class="sneakpeakvideo" [src]="modalItem?.demoUrl | sanitize" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen>
            </iframe>
          </div>
        </ng-container>
        <ng-template #elseTemplate2>
          <div class="col-12 card my-3 text-center no-data">
            <div class="">
              <h5> Please wait loading data...!</h5>
            </div>
          </div>
        </ng-template>
      </div>

    </div>
  </div>
</div>
