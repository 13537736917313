<section class="homepage-blocks newhomepage">
  <div class="container">
    <div class="right-left-block">
      <div class="finb-feature-content">
        <h3>
          MEET THE HERO <br>
          WHOSE COMMISSION IS ZERO
        </h3>
        <div class="mb-2">
          <ngx-typed-js [backSpeed]="10" [loop]="true" [typeSpeed]="50" [backDelay]="3000"
            [strings]="['Buy mutual funds with zero commission', 'Unbiased, research-backed recommendations', 'Detailed insights for informed investors', 'Periodic review to assess your Portfolio health']">
            <strong>
              <span class="typing"></span>
            </strong>
          </ngx-typed-js>
        </div>
        <p>
          When you buy direct mutual funds from Finbingo, you don’t pay commission. Finbingo Mutual Fund Platform helps
          you to choose & invest in funds best suited for you! <br>
          <a class="readmore-homepage" href="javascript:void(0);" routerLink="/mutual-funds">Learn More about Direct
            Mutual Funds <i class="far fa-angle-right"></i></a>
        </p>
        <a [href]="cmService.portalURL + '/InvestNow/mfInvestment'" class="btn btn-orangehomepage">Start Investing</a>
      </div>
      <div class="finb-featureimg">
        <img
          src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/mutual_fund_illustration-500w2.png"
          alt="Mutual Funds" title="Mutual Funds">
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks bg-light-gray newhomepage">
  <div class="container">
    <div class="left-right-block">
      <div class="finb-featureimg">
        <img
          src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/portfolio_doctor_illustration-500.png"
          alt="Portfolio Doctor" title="Portfolio Doctor">
      </div>
      <div class="finb-feature-content">
        <h3>
          PUT HEALTH BACK <br>
          INTO YOUR WEALTH
        </h3>
        <div class="mb-2">
          <ngx-typed-js [backSpeed]="10" [loop]="true" [typeSpeed]="50" [backDelay]="3000"
            [strings]="['Analyse your Consolidate Account Statement', 'Get detailed view of your Fund Performance', 'Identify and weed out non-performing funds', 'Maintain portfolio health by continuous monitoring']">
            <strong>
              <span class="typing"></span>
            </strong>
          </ngx-typed-js>
        </div>
        <p>
          Get your mutual fund portfolio analysed by <strong>Finbingo Portfolio Doctor.</strong> We will help you invest
          it better and maximize your investment potential!
          <!-- <a class="readmore-homepage" href="javascript:void(0);">Learn More <i class="far fa-angle-right"></i></a> -->
        </p>
        <a [href]="cmService.portalURL + '/InvestNow/mfPortDocAnalysis'" class="btn btn-orangehomepage">Analyse Now</a>
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks newhomepage">
  <div class="container">
    <div class="right-left-block">
      <div class="finb-feature-content">
        <h3>
          ACT TODAY TO <br>
          PLAN YOUR TOMORROW
        </h3>
        <div class="mb-2">
          <ngx-typed-js [backSpeed]="10" [loop]="true" [typeSpeed]="50" [backDelay]="3000"
            [strings]="['Comprehensive financial portfolio analysis', 'Identify avenues to grow your wealth', 'Assess feasibility of your financial goals']">
            <strong>
              <span class="typing"></span>
            </strong>
          </ngx-typed-js>
        </div>
        <p>
          Take the guesswork out of your financial planning with <strong>Finbingo Wealth Builder.</strong> Get
          comprehensive financial analysis for your current assets, liabilities, income and expenses.
          <br>
          <a class="readmore-homepage" href="javascript:void(0);" routerLink="/wealth-builder">Learn More about Wealth
            Builder <i class="far fa-angle-right"></i></a>
        </p>
        <a [href]="cmService.portalURL + '/grow-wealth/'" class="btn btn-orangehomepage">Grow your Wealth</a>
      </div>
      <div class="finb-featureimg">
        <img
          src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/wealth-builder2.png"
          alt="Wealth Builder" title="Wealth Builder">
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks bg-light-gray newhomepage">
  <div class="container">
    <div class="left-right-block">
      <div class="finb-featureimg">
        <img
          src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/SaveTax-Illustration-500A.png"
          alt="Tax Saver" title="Tax Saver">
      </div>
      <div class="finb-feature-content">
        <h3>
          CUT YOUR TAX <br>
          WITH ULTIMATE AXE
        </h3>
        <div class="mb-2">
          <ngx-typed-js [backSpeed]="10" [loop]="true" [typeSpeed]="50" [backDelay]="3000"
            [strings]="['Stay on top of your Tax liabilities', 'Unbiased, expert tax planning advice', 'Build wealth while saving taxes']">
            <strong>
              <span class="typing"></span>
            </strong>
          </ngx-typed-js>
        </div>
        <p>
          Expert tax advisory backed by Artificial Intelligence, Finbingo Tax Planner helps you maximize tax savings and
          helps save tax right down to the last rupee. Stay on top of your tax liabilities throughout the year <br>
          <a class="readmore-homepage" href="javascript:void(0);" routerLink="/tax-planning-advisor">Learn More about
            Saving Tax <i class="far fa-angle-right"></i></a>
        </p>
        <a [href]="cmService.portalURL + '/save-tax'" class="btn btn-orangehomepage">Start Saving Now</a>
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks newhomepage">
  <div class="container">
    <div class="right-left-block">
      <div class="finb-feature-content">
        <h3>
          IT’S NEVER EARLY TO PLAN <br>
          FOR YOUR GOLDEN AGE
        </h3>
        <div class="mb-2">
          <ngx-typed-js [backSpeed]="10" [loop]="true" [typeSpeed]="50" [backDelay]="3000"
            [strings]="['Wealth creation for your retirement', 'Save additional tax on investments upto ₹ 50,000', 'Enjoy regular pension income on retirement']">
            <strong>
              <span class="typing"></span>
            </strong>
          </ngx-typed-js>
        </div>
        <p>
          Invest in National Pension Scheme from Finbingo with the assurance of income security in your old age.
          <strong>Finbingo NPS Platform</strong> helps you to buy a low-cost mix of equity, corporate bonds & government
          debt & accumulate a corpus for retirement in a tax-efficient manner.

          <br>
          <a class="readmore-homepage" href="javascript:void(0);" routerLink="/nps">Learn more about NPS <i
              class="far fa-angle-right"></i></a>
        </p>
        <a href="https://cra.kfintech.com/poponline/popverification?AuthID=ECB2F2647F94945220C6B7D61A255791F397125CFB3A0A31867D10B329DE8E87E878A94DD4E677D3865D99881DC16C8BD61B02561F5665E1437750A28096F70E17A20242D785DE5A9E6F71FE35EC8C53"
          class="btn btn-orangehomepage">Apply Now</a>
      </div>
      <div class="finb-featureimg">
        <img
          src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/NationalPensionScheme.png"
          alt="Wealth Builder" title="Wealth Builder">
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks newhomepage">
  <div class="container">
    <div class="finb-mygoals">
      <h4>Financial Advisory and Portfolio Management</h4>
      <p class="mb-5">
        We have democratize the investment process by giving access to the same level of quality financial advice to
        all
        investors irrespective of their investment size. Customers get unbiased, customized, AI-driven advice for the
        purchase
        of mutual funds, health and life insurance, along with other financial products.
      </p>
      <div class="advisory-list">
        <div class="advisor-list-item advisor-blue">
          <i class="fas fa-user"></i>
          <div>
            <h4>Client Interest First</h4>
            <p>
              At Finbingo, our goal is to put your interests first and foremost. We apply our deep insights of the
              financial market to
              recommend products that match your financial needs, immediate and future.
            </p>
          </div>
        </div>
        <div class="advisor-list-item advisor-yellow">
          <i class="fal fa-globe-stand"></i>
          <div>
            <h4>360° Financial Solution</h4>
            <p>
              Our robo-advisor assesses your accurate financial health by analysing the quality of your investments,
              comparing it with
              a model portfolio and thereafter providing an immediate solution.
            </p>
          </div>
        </div>
        <div class="advisor-list-item advisor-green">
          <i class="far fa-chart-line"></i>
          <div>
            <h4>Research Capabilities</h4>
            <p>
              Research becomes easy with customer centricity as the focal point. Our award winning research only
              considers the best
              possible investment avenues that you must invest in.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks newhomepage bg-light-gray">
  <div class="container">
    <div class="finb-mygoals">
      <h4>Why Invest With Finbingo?</h4>
      <br>
      <ul class="what-you-get greenchecklist">
        <li>
          <i class="greencheckbox far fa-check"></i>
          <h4>India's most intelligent robo advisor</h4>
          <p>
            Get access to India’s smartest robo-advisor to manage your wealth based on your expectations
          </p>
        </li>
        <li>
          <i class="greencheckbox far fa-check"></i>
          <h4>Completely unbiased financial advice</h4>
          <p>
            All clients receive AI-driven, customized financial advice that is based on data and not human biases.
          </p>
        </li>
      </ul>
      <ul class="what-you-get greenchecklist">
        <li>
          <i class="greencheckbox far fa-check"></i>
          <h4>The most advanced goal setting algorithm</h4>
          <p>
            Your funds are allocated, managed and optimized with no human intervention.
          </p>
        </li>
        <li>
          <i class="greencheckbox far fa-check"></i>
          <h4>A democratized investment process</h4>
          <p>
            Irrespective of the size of an investment, all investors receive the same quality of advice and service
          </p>
        </li>
      </ul>
    </div>
  </div>
</section>

<section class="homepage-blocks newhomepage">
  <div class="container">
    <div class="finb-mygoals">
      <h4>Finbingo Wealth Platform – Leverages the Best for You</h4>
      <br>
      <ul class="what-you-get">
        <li>
          <i class="far fa-arrow-right"></i>
          <h4>360 degrees wealth planning</h4>
          <p>
            Introducing the most advanced wealth planning and financial goals simulation engine to keep you in top
            financial health
          </p>
        </li>
        <li>
          <i class="far fa-arrow-right"></i>
          <h4>44 Mutual Fund Houses (AMCs)</h4>
          <p>
            Our research and deep insights allow us to select the top mutual fund houses to entrust your hard-earned
            money to.
          </p>
        </li>
      </ul>
      <ul class="what-you-get">
        <li>
          <i class="far fa-arrow-right"></i>
          <h4>No conflict of interest</h4>
          <p>
            We receive zero commission from any mutual fund scheme, thus ensuring our advice is unbiased and there is no
            conflict of
            interest.
          </p>
        </li>
        <li>
          <i class="far fa-arrow-right"></i>
          <h4>Emotion-free Investing</h4>
          <p>
            All investment recommendations are data and AI-driven, ensuring the final decision is completely without any
            human bias.
          </p>
        </li>
      </ul>
      <ul class="what-you-get">
        <li>
          <i class="far fa-arrow-right"></i>
          <h4>Low Fees</h4>
          <p>
            We take no commission and we will charge you a reasonable annual fee for giving us the opportunity to help
            you build a
            corpus of wealth.
          </p>
        </li>
        <li>
          <i class="far fa-arrow-right"></i>
          <h4>Regular Rebalancing</h4>
          <p>
            At Finbingo we have your best interest at heart. A quarterly review allows us to rebalance your portfolio to
            ensure you
            are continually making the most of your investments.
          </p>
        </li>
      </ul>
      <ul class="what-you-get">
        <li>
          <i class="far fa-arrow-right"></i>
          <h4>Tax-Efficient Investing</h4>
          <p>
            Each investment is taken into consideration to extend you maximum tax benefit to reduce your tax liability.
          </p>
        </li>
        <li>
          <i class="far fa-arrow-right"></i>
          <h4>Hands off investing</h4>
          <p>
            The process is automated and driven by smart algorithms, taking away most effort from managing your
            investments.
          </p>
        </li>
      </ul>
    </div>
  </div>
</section>

<section class="download-androidappwrap">
  <div class="container">
    <div class="download-androidapp">
      <div class="download-app-text">
        <h5> Download Our App </h5>
        <p> Download the app and plan your taxes, grow your wealth and invest in Mutual funds, all this at your
          fingertips!
        </p>
      </div>
      <div class="play-store-btns">
        <a href="https://play.google.com/store/apps/details?id=com.ssbainnovations.finbingo">
          <img alt="Finbingo App"
            src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/play-store.svg"
            alt="finbingo app" title="finbingo app">
        </a>
        <a href="https://apps.apple.com/in/app/finbingo-plan-invest-in-mf/id1531059811">
          <img alt="Finbingo App"
            src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/app-store.svg"
            alt="finbingo app" title="finbingo app">
        </a>
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks bg-light-gray newhomepage">
  <div class="container">
    <div class="right-left-block">
      <div class="finb-feature-content">
        <h3>Why Finbingo Exists?</h3>
        <p>
          The origins of Finbingo can be traced back to decades of experience working with financial advisors across
          the board,
          when we came to the conclusion that most financial advisors were not acting in the best interest of the
          investor.
          And we decided to bring your interest at core.
          <br>
          <a href="javascript:void(0);" class="readmore-homepage" routerLink="/our-story">Learn More about Finbingo <i
              class="far fa-angle-right"></i></a>
        </p>
      </div>
      <div class="finb-feature-content">
        <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/Team2.png"
          alt="Finbingo" title="Finbingo" width="100%">
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks newhomepage funds-onfly">
  <div class="container">
    <div class="homepage-fundswrap position-relative fund-houses-list">
      <h4 class="homepage-fundstitle">Mutual Fund Houses</h4>
      <ul class="homepage-fundslist" [class.show-content]="!toggleData.fundHouses">
        <li>
          <a routerLink="/amc/sbi-mutual-fund">
            SBI Funds Management Private Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/hdfc-mutual-fund">
            HDFC Asset Management Company Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/icici-prudential-mutual-fund">
            ICICI Prudential Asset Management Company Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/birla-sun-life-mutual-fund">
            Aditya Birla Sun Life AMC Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/kotak-mutual-fund">
            Kotak Mahindra Asset Management Company Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/axis-asset-mutual-fund">
            Axis Asset Management Company Ltd.
          </a>
        </li>
        <li>
          <a routerLink="/amc/mirae-mutual-fund">
            Mirae Asset Investment Managers (India) Private Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/ppfas-mutual-fund">
            PPFAS Asset Management Pvt. Ltd.
          </a>
        </li>
        <li>
          <a routerLink="/amc/dsp-mutual-fund">
            DSP Investment Managers Private Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/invesco-mutual-fund">
            Invesco Asset Management Company Pvt Ltd.
          </a>
        </li>
        <li>
          <a routerLink="/amc/bnp-paribas-mutual-fund">
            BNP Paribas Asset Management India Pvt. Ltd.
          </a>
        </li>
        <li>
          <a routerLink="/amc/baroda-pioneer-mutual-fund">
            Baroda Asset Management India Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/canara-robeco-mutual-fund">
            Canara Robeco Asset Management Company Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/lnt-investment-mutual-fund">
            L&T Investment Management Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/quant-money-mutual-fund">
            Quant Money Managers Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/franklin-templeton-mutual-fund">
            Franklin Templeton Asset Management (India) Private Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/hsbc-mutual-fund">
            HSBC Global Asset Management (India) Private Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/jm-financial-mutual-fund">
            JM Financial Asset Management Private Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/lic-mutual-fund">
            LIC Mutual Fund Asset Management Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/principal-mutual-fund">
            Principal Asset Management Company Private Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/quantum-mutual-fund">
            Quantum Asset Management Company Private Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/reliance-mutual-fund">
            Nippon Life India Asset Management Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/idfc-mutual-fund">
            IDFC Asset Management Company Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/sundaram-mutual-fund">
            Sundaram Asset Management Company Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/tata-mutual-fund">
            Tata Asset Management Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/taurus-mutual-fund">
            Taurus Asset Management Company Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/uti-mutual-fund">
            UTI Asset Management Company Private Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/boi-axa-mutual-fund">
            BOI AXA Investment Managers Private Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/edelweiss-mutual-fund">
            Edelweiss Asset Management Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/essel-mutual-fund">
            Essel Finance AMC Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/motilal-oswal-mutual-fund">
            Motilal Oswal Asset Management Company Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/idbi-mutual-fund">
            IDBI Asset Management Ltd.
          </a>
        </li>
        <li>
          <a routerLink="/amc/pgim-india-mutual-fund">
            PGIM India Asset Management Private Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/union-kbc-mutual-fund">
            Union Asset Management Company Pvt. Ltd.
          </a>
        </li>
        <li>
          <a routerLink="/amc/iifl-mutual-fund">
            IIFL Asset Management Co. Ltd.
          </a>
        </li>
        <li>
          <a routerLink="/amc/indiabulls-mutual-fund">
            Indiabulls Asset Management Company Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/il-fs-mutual-fund">
            IL&FS Infra Asset Management Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/shriram-mutual-fund">
            Shriram Asset Management Company Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/iifcl-mutual-fund">
            IIFCL Asset Management Company Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/mahindra-mutual-fund">
            Mahindra Manulife Investment Management Private Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/yes-mutual-fund">
            YES Asset Management (India) Limited
          </a>
        </li>
        <li>
          <a routerLink="/amc/iti-mutual-fund">
            ITI Asset Management Limited
          </a>
        </li>
      </ul>
      <a href="javascript:void(0)" class="showMoreBtn" matTooltipPosition="above"
        [title]="!toggleData.fundHouses ? 'Show more' : 'Show less'"
        (click)="toggleData.fundHouses = !toggleData.fundHouses"><i class="far"
          [ngClass]="toggleData.fundHouses ? 'fa-angle-up' : 'fa-angle-down bounce'"></i>
      </a>
    </div>

    <div class="homepage-fundswrap">
      <h4 class="homepage-fundstitle">NPS Fund Houses</h4>
      <!-- <p>NPS allows a subscriber to choose from 7 Pension fund managers that are appointed by PFRDA (Pension Fund Regulatory and Development Authority of India). A subscriber can choose a fund manager based on the track record of the manager and preference. At present, you can choose any one of the following pension funds.
      </p> -->
      <ul class="homepage-fundslist">
        <li>
          <a routerLink="/nps/hdfc-pension">
            HDFC Pension Management Company Ltd
          </a>
        </li>
        <li>
          <a routerLink="/nps/icici-pension">
            ICICI Prudential Pension Fund
          </a>
        </li>
        <li>
          <a routerLink="/nps/lic-pension">
            LIC Pension Fund Ltd
          </a>
        </li>
        <li>
          <a routerLink="/nps/kotak-pension">
            Kotak Mahindra Pension Fund
          </a>
        </li>
        <li>
          <a routerLink="/nps/sbi-pension">
            SBI Pension Fund
          </a>
        </li>
        <li>
          <a routerLink="/nps/uti-pension">
            UTI Retirement Solutions Pension Fund
          </a>
        </li>
        <li>
          <a routerLink="/nps/absl-pension">
            Birla Sunlife Pension Management Ltd
          </a>
        </li>
      </ul>
    </div>


    <div class="homepage-fundswrap">
      <h4 class="homepage-fundstitle">Fund Categories</h4>
      <ul class="homepage-fundslist">
        <li>
          <a [href]="cmService.portalURL + '/InvestNow/mfInvestment?categoryId=1'">Equity</a>
        </li>
        <li>
          <a [href]="cmService.portalURL + '/InvestNow/mfInvestment?categoryId=2'">Hybrid</a>
        </li>
        <li>
          <a [href]="cmService.portalURL + '/InvestNow/mfInvestment?categoryId=3'">Debt</a>
        </li>
        <li>
          <a [href]="cmService.portalURL + '/InvestNow/mfInvestment?categoryId=4'">Commodity</a>
        </li>
        <li>
          <a [href]="cmService.portalURL + '/InvestNow/mfInvestment?classcodes=8'">ELSS Tax Savings</a>
        </li>
      </ul>
    </div>
    <div class="homepage-fundswrap">
      <h4 class="homepage-fundstitle">Calculators</h4>
      <ul class="homepage-fundslist">
        <li>
          <a [href]="cmService.portalURL +'/calculator/goal-calc'">Goals SIP Calculator</a>
        </li>
        <li>
          <a [href]="cmService.portalURL +'/calculator/hlv-calc'">HLV Calculator</a>
        </li>
        <li>
          <a [href]="cmService.portalURL +'/calculator/sip-calc'">SIP Calculator</a>
        </li>
        <li>
          <a [href]="cmService.portalURL +'/calculator/new-budget-calc'">New Budget Tax Calculator</a>
        </li>
      </ul>
    </div>
    <div class="homepage-fundswrap">
      <h4 class="homepage-fundstitle">Easy Mutual Fund Picks</h4>
      <ul class="homepage-fundslist">
        <li>
          <a [href]="cmService.portalURL + '/InvestNow/easy-picks?code=TAX_SAVING'">Tax Saving Fund</a>
        </li>
        <li>
          <a [href]="cmService.portalURL + '/InvestNow/easy-picks?code=BETTER_THAN_FD'">Better than FD</a>
        </li>
        <li>
          <a [href]="cmService.portalURL + '/InvestNow/easy-picks?code=INSTA_REDEEM'">Insta Redeem</a>
        </li>
        <li>
          <a [href]="cmService.portalURL + '/InvestNow/easy-picks?code=SECTOR_BETS'">Sector Bets</a>
        </li>
        <li>
          <a [href]="cmService.portalURL + '/InvestNow/easy-picks?code=WEALTH_BUILDERS'">Wealth Builders</a>
        </li>
        <li>
          <a [href]="cmService.portalURL + '/InvestNow/easy-picks?code=GLOBAL_BRANDS'">Global Brands</a>
        </li>
        <!-- <li>
          <a [href]="cmService.portalURL + '/InvestNow/easy-picks?code=LONG_TERM'">Long Term</a>
        </li>
        <li>
          <a [href]="cmService.portalURL + '/InvestNow/easy-picks?code=LOW_RISK'">Low Risk</a>
        </li>
        <li>
          <a [href]="cmService.portalURL + '/InvestNow/easy-picks?code=HIGH_RISK_REWARD'">High-Risk-Reward</a>
        </li> -->
      </ul>
    </div>
  </div>
</section>

<!-- <section class="pb-3 newhomepage">
  <div class="container">
    <div class="finb-bse-reg">
      <div class="right-bdr">
        <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/sebi-logo.jpg"
          alt="SEBI" title="SEBI">
        <h3 class="font">
          SEBI Registered Investment Advisor
        </h3>
      </div>
      <div class="right-bdr">
        <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/PFRDA.png"
          alt="PFRDA" title="PFRDA">
        <h3 class="font">
          PFRDA Registered NPS Distributor
        </h3>
      </div>
      <div class="right-bdr">
        <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/amfi-logo.jpg"
          alt="AMFI" title="AMFI">
        <h3 class="font">
          AMFI Registered Mutual Fund Advisor
        </h3>
      </div>
      <div class="left-pad">
        <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/bse-logo.jpg"
          alt="BSE" title="BSE">
        <h3 class="font">
          BSE Registered Mutual Fund Advisor
        </h3>
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="disclaimer homepage-blocks newhomepage">
  <div class="container">
    <h4>DISCLAIMER</h4>
    <p>
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, illum atque incidunt assumenda optio eos, sunt
      veritatis earum repellat ratione enim minima nobis velit dolore quia laudantium molestias doloremque modi. Lorem
      ipsum dolor, sit amet consectetur adipisicing elit. Magnam, illum atque incidunt assumenda optio eos, sunt
      veritatis earum repellat ratione enim minima nobis velit dolore quia laudantium molestias doloremque modi.
    </p>
  </div>
</section> -->


<section class="homepage-blocks newhomepage bg-light-gray">
  <div class="container">
    <div class="regulatory-disclosure">
      <h4>Regulatory Disclosure</h4>
      <div class="table-responsive">
        <table class="table new-table">
          <thead>
            <tr>
              <th>Name of Investment Adviser as registered with SEBI</th>
              <th>Registration Type</th>
              <th>Registration No.</th>
              <th>Validity of Registration</th>
              <th>Address</th>
              <th>Contact details of the Principal Officer</th>
              <th>Corresponding SEBI regional/local office address</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SSBA INNOVATIONS LTD. (Trade name: Finbingo.com)</td>
              <td>Non- Individual</td>
              <td>INA000012166</td>
              <td>12-Dec-2018 till perpetual</td>
              <td>1309, LODHA SUPREMUS POWAI, SAKI VIHAR ROAD, OPP. MTNL OFFICE, POWAI, Mumbai City, Mumbai, Maharashtra, 400072, India</td>
              <td>
                <a [href]="'mailto:' + emailAddress">{{ emailAddress }}</a><br>
                <a href="tel:9321908755">9321908755</a>
              </td>
              <td>SEBI Bhavan BKC Plot No. C-A, 'G' BLOCK, Bandra-Kurla Complex, Bandra(East), Mumbai- 400051</td>
            </tr>
          </tbody>

        </table>
      </div>
      <p>
        SEBI requires RIA’s to disclose complaint status on their website. Pursuant to the same, please see below-
      </p>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th colspan="5">NUMBER OF COMPLAINTS AS ON
                <span [innerHTML]="lastMonthDate"></span>
              </th>
            </tr>
            <tr>
              <th>At the beginning of the month</th>
              <th>Received during the month</th>
              <th>Resolved during the month</th>
              <th>Pending at the end of the month</th>
              <th>Reason for pending status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>
