import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { OfferingsModule } from './components/offerings/offerings.module';
import { DataService } from './services/data.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AboutModule } from './components/about/about.module';
import { LearnModule } from './components/learn/learn.module';
import { GetStartedComponent } from './components/get-started/get-started.component';
import { HeaderComponent } from './global/header/header.component';
import { FooterComponent } from './global/footer/footer.component';
import { AuthDialogComponent } from './global/auth-dialog/auth-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SeoService } from './services/seo.service';
import { OthersModule } from './components/others/others.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './global/page-not-found/page-not-found.component';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { HttpClientInterceptor } from './helpers/http-interceptor/http-client-interceptor';
import { LoaderComponent } from './global/loader/loader.component';
import { SharedModule } from './helpers/shared/shared.module';
import { CommonService } from './services/common.service';
import { ElssComponent } from './components/landing-pages/elss/elss.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CountDownComponent } from './global/count-down/count-down.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PartnersComponent } from './components/partners/partners.component';

@NgModule({
  declarations: [
    //Components
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    PageNotFoundComponent,
    HomeComponent,
    GetStartedComponent,
    AuthDialogComponent,
    ElssComponent,
    CountDownComponent,
    PartnersComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    OfferingsModule,
    AboutModule,
    LearnModule,
    OthersModule,
    NgxTypedJsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
    SharedModule,
    NgxSliderModule,
    CarouselModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    AuthDialogComponent
  ],
  providers: [DataService, SeoService,CommonService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
