import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-srinivas-reddy',
  templateUrl: './srinivas-reddy.component.html',
  styleUrls: ['./srinivas-reddy.component.css']
})
export class SrinivasReddyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
