import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fnb-media',
  templateUrl: './fnb-media.component.html',
  styleUrls: ['./fnb-media.component.css']
})
export class FnbMediaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
