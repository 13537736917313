<section class="homepage-blocks">
  <div class="container finbWbBanner">
    <div class="row text-center justify-content-center mb-5">
      <div class="col-lg-10">
        <!-- <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/NEW-MAIN-IMG3.jpg" width="100%" alt=""> -->
        <h1 class="page-bigtitle">Team Finbingo</h1>
        <!-- <h1 class="page-bigtitle orange">Wealth Analysis and Advisory, Simplified.</h1> -->
        <p>
          <strong>Team Finbingo draws its strength from the best minds in the industry.</strong>
        </p>
        <p>
          The people behind Finbingo come from diverse industries, including technology, finance and government
          departments. Their
          passion, and collective years of experience and knowledge is what forms the foundation for Finbingo.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks">
  <div class="container">
    <h4 class="text-center management-title">Management</h4>
    <div class="why-finbingo-wb finbWhitebox">
      <div class="finb-feature-content">
        <div class="management-list">
          <div class="management-item">
            <div class="manager-img">
              <img
                src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/sujith-bangar-finbingo-.jpg"
                alt="sujit bangar" title="sujit bangar">
            </div>
            <h5 class="manager-name">Sujit Bangar</h5>
            <p>
              Sujit Bangar is a post graduate from Harvard Business School and has worked with the Indian Revenue
              Service for 13
              years
              before resigning as Joint Commissioner.
            </p>
            <p>
              <strong>More About <a [routerLink]="['/sujit-bangar']">Sujit Bangar</a></strong>
              <!-- <strong>More About <a [routerLink]="[ '/sujit-bangar' ]">Sujit Bangar</a></strong> -->
            </p>
          </div>
          <div class="management-item">
            <div class="manager-img">
              <img
                src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/samir-jayaswal-finbingo.jpg"
                alt="Samir Jaiswal" title="Samir Jaiswal">
            </div>
            <h5 class="manager-name">Samir Jayaswal</h5>
            <p>
              Samir is a Computer Science Graduate and MBA in International Finance. He is currently pursuing PhD in
              Financial
              Markets. He has 20 years of high-quality experience in senior positions in companies like Wipro, ICICI
              Group, etc.
            </p>
            <p>
              <strong>More About <a [routerLink]="['/samir-jayaswal']">Samir Jayaswal</a></strong>
            </p>
          </div>
          <div class="management-item">
            <div class="manager-img">
              <img
                src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/atul-rege-finbingo.jpg"
                alt="Atul Rege" title="Atul Rege">
            </div>
            <h5 class="manager-name">Atul Rege</h5>
            <p>
              Atul Rege is a passionate investor and has been in the securities brokerage domain for more than the 20
              years & is a SEBI Certified Registered Investment Advisor (RIA).
            </p>
            <p>
              <strong>More About <a [routerLink]="['/atul-rege']">Atul Rege</a></strong>
            </p>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <div class="why-finbingo-wb finbWhitebox">
      <div class="finb-feature-content">
        <div class="management-list">
          <div class="management-item">
            <div class="manager-img">
              <img
                src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/sanjay-godbole-finbingo.jpg"
                alt="Sanjay Godbole" title="Sanjay Godbole">
            </div>
            <h5 class="manager-name">Sanjay Godbole</h5>
            <p>
              Sanjay leads the daily operations at SSBA Innovations, including product development. His experience at
              Infosys
              encompasses varied skills viz. program management, strategy building, business consulting and solution
              development.
            </p>
            <p>
              <strong>More About <a [routerLink]="['/sanjay-godbole']">Sanjay Godbole</a></strong>
            </p>
          </div>
          <div class="management-item">
            <div class="manager-img">
              <img
                src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/srinivas-reddy-finbingo.jpg"
                alt="Srinivas Reddy" title="Srinivas Reddy">
            </div>
            <h5 class="manager-name">Srinivas Reddy</h5>
            <p>
              Srini, a post graduate in Computer Science, leads our engineering team and is responsible for Finbingo’s
              underlying technology. His focus and passion is on the latest technology trends and backend architecture.
            </p>
            <p>
              <strong>More About <a [routerLink]="['/srinivas-reddy']">Srinivas Reddy</a></strong>
            </p>
          </div>
          <div class="management-item">
            <div class="manager-img">
              <img
                src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/Vidyasagar.jpg"
                alt="Vidyasagar Patil" title="Vidyasagar Patil">
            </div>
            <h5 class="manager-name">Vidyasagar Patil</h5>
            <p>
              Vidyasagar Patil is Doctor by qualification and belongs to 2004 batch of IRS. In his 15 years of service
              with Indian
              Revenue Service (IRS), he has worked at various senior levels in the Ministry of Finance, Department of
              Revenue.
            </p>
            <p>
              <strong>More About <a [routerLink]="['/vidyasagar-patil']">Vidyasagar Patil</a></strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>