<section class="pt-5 pb-0">
  <div class="container finbWbBanner">
    <div class="row text-center justify-content-center">
      <div class="col-lg-10">
        <h1 class="page-bigtitle">Privacy Policy</h1>
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks">
  <div class="container">
    <div class="">
      <div class="finb-feature-content">
        <p>
          At Finbingo, (SSBA Innovations Limited, also referred as SSBA Innovations), we pride ourselves on our commitment
          to protecting the privacy of our customer. Our relationship with you is our most important asset. Our stewardship
          principles reflect our company’s values and how we strive to maintain your privacy.
        </p>
        <p>
          We want you to feel comfortable and confident when using our products and services and with entrusting your personal
          financial and tax return information to us.
        </p>
        <p>
          <strong>
            Privacy Practices and Principles
          </strong>
          <br>
          SSBA Innovations Limited offers a wide range of financial product services including financial product
          distribution and advice, tax services, including tax preparation, tax planning, and Scrutiny and Tax query services in
          India. The following guidelines set forth general privacy principles that apply to information collected on the Website,
          independent of any particular business relationship you enter into with SSBA Innovations Private Ltd. If you enter into
          a business relationship with SSBA Innovations as our customer (such as when you are buying financial products through us
          or utilizing our tax preparation or other software or online tax preparation tools), we will make you aware of the
          privacy practices that apply to that particular business or relationship.
        </p>
        <p>
          This Privacy Statement applies to our sites, products, and services, that link to this Statement.
        </p>
        <p>
          <strong>Personal Information We Collect and how we use it</strong>
          <br>
          When you visit https://www.finbingo.com you are not required to provide any Personal Information unless you choose to
          avail our online services or signup for free newsletter or other services which are described below.
        </p>
        <p>
          <strong>Newsletters</strong> <br>
          When you subscribe to newsletters we collect your email address to e- mail you the newsletters.
        </p>
        <p>
          We want to be clear about what information we collect and how we use it to deliver our Services to you, improve your
          financial life, operate our business, and help make our Services useful, more intelligent, and work better for you. We
          do not sell or share your Personal Information with third parties for their own commercial uses without your consent at
          any cost.
        </p>
        <p>
          <strong>Registration</strong>
          <br>
          In order to avail all our financial products distribution services, you must register yourself on the Website and you
          must provide your email address, a password. This information is collected for personal identification, to provide the
          financial products distribution services, to allow us to contact you for customer service purpose, to mail you the
          investment updates, newsletter and for marketing communication with you.
        </p>
        <p>
          <strong>Information Collection</strong>
          <br>
          In order to avail finbingo’s services you must provide your name, address, email address, phone number, permanent
          account number (“PAN”), bank account number, MICR number, income and deductions data, expenses data, asset and income
          details, vouchers, bank statements, form 16, Form 26AS, tax payment challans, debit and credit account numbers and its
          expiration and any other information as may be required to prepare your financial profile. This information is collected
          for transacting in mutual funds, insurance, loans, forecasting your financial net worth, calculation of goals,
          assessment of future liabilities, to complete the payment transaction, customer service purposes, marketing
          communication with you.
        </p>
        <p>
          <strong>Web technologies</strong>
          Finbingo uses your personal information to manage your account, to contact you and to operate, improve, and deliver our
          products and services, including Web sites. We use your personal information to provide you with information and
          services you request, to tell you about Finbingo products and services, and to give you a customized, optimized,
          interactive experience as you use our products. We use your personal information to charge you for the products and
          services you purchase. We use the contact information you provide to communicate with you. We use your financial
          information to help you invest in financial products.
        </p>
        <p>
          We may collect information through the following Web technologies: We utilize a software technology called “cookies”.
          Internet cookies allow us to customize our Website for you by placing small files on your computer as you visit various
          components of https://www.finbingo.com.
        </p>
        <p>
          Through the use of cookies, we can display only those items that may be of interest to you on a particular site, or make
          it easier for you to access your account information. In most cases, however, information we collect through Internet
          cookies does not identify you personally.
        </p>
        <p>
          Like most standard Website servers, SSBA Innovations Limited uses log files. Information collected in log files
          may include internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages,
          platform type, date/time stamp, and number of clicks. We utilize this information to analyze trends, administer the
          site, track user’s movement in the aggregate, and gather broad demographic information for aggregate use. This
          information is not personally identifiable. When you use certain financial products purchase or sale services, IP
          addresses are personally identifiable. When you use calculators and other tools on our Websites that do not require
          registration, SSBA Innovations Limited may collect non-identifiable data from such tools, such as the number of
          people that use a certain tool, time and geography from where they use and how long they stay on the website etc. SSBA
          Innovations Private Limited may also set cookies or clear gifs based upon your use of tools and calculators to help us
          customize offers and tools to your particular interests. SSBA Innovations Limited will not record or track any
          data you enter into standalone tools and calculators in any way that identifies you personally, unless we specifically
          inform you that we are doing so at the time you provide such data.
        </p>
        <p>
          From time to time we may add or enhance services available on the Website. To the extent these services are provided,
          and used by you, we will use the information you provide to facilities the service. For example, if you email us with a
          question, we will use your email address etc. We may also store such information to assist us in making the Website
          better and easier to use.
        </p>
        <p>
          <strong>How We May Disclose Your Information</strong>
          <br>
          Information that SSBA Innovations Private Ltd collects from you is controlled by the Information Technology Act, 2000,
          other relevant laws, and SSBA Innovations policies. Any information that is collected from you as part of your customer
          relationship(s) with SSBA Innovations Private Ltd, through a retail office will be governed by the privacy policy.
        </p>
        <p>
          For information collected on https://www.finbingo.com (as described above), SSBA Innovations Private Ltd or any person
          authorized on its behalf may transfer sensitive personal data or information including any information, to any other
          body corporate or person in India, or located in any other country, which ensures the same level of data protection that
          is adhered by SSBA Innovations Private Ltd as provided for under the Rules made under the information technology Act,
          2000. SSBA Innovations Private Ltd may also use and disclose such information to the following categories of persons:
        </p>
        <p>
          <strong>Uses and Disclosures by SSBA Innovations Private Ltd:</strong>
          Where permitted by law, SSBA Innovations Private Ltd may use the information collected on this Website to make you aware
          of products and services offered by SSBA Innovations Private Ltd and its affiliated companies.
        </p>
        <p>
          <strong>Third Parties:</strong>
          We do not share your personal information with non-affiliated third parties for marketing purposes except
          as permitted by applicable law or with your consent. We do not sell or rent your personal information to third party
          direct marketers.
        </p>
        <p>
          We may access and/or disclose your information if it is necessary to comply with the law or legal process, to protect or
          defend Finbingo. For example, we may be required to cooperate with regulators or law enforcement action such as a court
          order, subpoena or search warrant.
        </p>
        <p>
          <strong>KYC validating agencies and other related entities</strong><br>
          We will pass your KYC details to appropriate designated agencies to validate your KYC status, KYC documentation status,
          Aadhaar authentication, PAN validity checks and to check/ retrieve your KYC details, your bank for generation of
          automated financial profile, to tax department for accessing your Form 26AS etc. This is done to automate several data
          items which you would otherwise need to manually enter to avail services offered by Finbingo
        </p>
        <p>
          <strong>Affiliates</strong>
          <br>
          SSBA Innovations Private Ltd may provide information it collects to its affiliates as permitted by law. For example, we
          disclose information to our affiliates in order to respond to your requests for information or services, or to help
          limit your receipt of marketing materials you have requested not to receive.
        </p>
        <p>
          <strong>Service Providers</strong>
          Where permitted by applicable law, we may disclose your personal information to service providers who perform business
          functions or hosting services on our behalf and who may be located in or outside of India. We require SSBA Innovations
          Private Ltd service providers to have written contracts that specify appropriate use of your personal information,
          require them to safeguard your personal information, and prohibit them from making unauthorized or unlawful use of your
          personal information. Joint Marketing Arrangements.
        </p>
        <p>
          Persons Who Acquire Our Assets or Business. If SSBA Innovations Private Ltd sells or transfers any of its business or
          assets, certain information about our clients may be a part of that sale or transfer. In the event of such a sale or
          transfer, SSBA Innovations Private Ltd will notify you. The notification procedure will be the same as the procedure we
          use to notify you of a change in our privacy statement as described below.
        </p>
        <p>
          Persons Who Request Information for Legal Purposes. We may disclose your information to affiliates or non-affiliated
          third parties when we have a good faith belief that such disclosure is required by law. This may occur in connection
          with a court order, legal process, or other judicial, administrative or investigative proceeding that produces a request
          for information from SSBA Innovations Private Ltd.
        </p>
        <p>
          <strong>Government Agency</strong> <br>
          Information shall be shared with Government agencies mandated under the law to obtain information including sensitive
          personal data or information for the purpose of verification of identity or for prevention, detection, investigation
          including cyber incidents, prosecution, and punishment of offences provided the Government agencies states that
          information so obtained shall not be published or shared with any other person.
        </p>
        <p>
          We may also disclose aggregate information compiled using your personal information that does not identify you
          individually or personally. This may include, for example, the total number of visitors to our Website from a particular
          state, or the average age of a user of our Websites.
        </p>
        <p>
          <strong>Changes to Our Privacy Statement</strong><br>
          Please note that, we may change this Online Privacy Policy from time to time. The effective date of this policy, as
          stated below, indicates the last time this policy was revised or materially changed. Checking the effective date below
          allows you to determine whether there have been changes since the last time you reviewed the Policy.
        </p>
        <p>
          <strong>Effective Date</strong><br>
          This policy was last modified in July 2018.
        </p>
        <p>
          By entering and using its content it will be implied that you have accepted our privacy practices and principles and
          that you are bound by it. In no case will SSBA Innovations Private Ltd be responsible for any loss/ harm to you on
          account of you not being updated with any changes in our privacy practices and principles.
        </p>
        <p>
          <strong>Security SSBA Innovations</strong>
          <br>
          Private Ltd has implemented generally accepted standards of technology and operational security in order to protect
          personally identifiable information from loss, misuse, alteration or destruction. All Personal Information you provide
          on the Website is transmitted using SSL (Secured Socket Layer) encryption. SSL is coding system which lets your browser
          automatically encrypts, or scramble, data before you send it to us. All payments on the Website are VeriSign SSL
          secured. Only authorized SSBA Innovations Private Ltd personnel are provided access to personally identifiable
          information and these employees have agreed to ensure confidentiality of this information
        </p>
        <p>
          <strong>Social Media Platforms</strong><br>
          You may wish to participate in social media platform hosted by SSBA Innovations Private Ltd which we make available to
          you. The main aim of the social media platform is to facilitate and allow you to share content. However, SSBA
          Innovations Private Ltd cannot be held responsible if you share personal information on Social media platform that is
          subsequently used, misused or otherwise appropriate by another user.
        </p>
      </div>
    </div>
  </div>
</section>