import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactUsComponent } from './components/about/contact-us/contact-us.component';
import { OurStoryComponent } from './components/about/our-story/our-story.component';
import { AtulRegeComponent } from './components/about/team-finbingo/atul-rege/atul-rege.component';
import { SamirJayaswalComponent } from './components/about/team-finbingo/samir-jayaswal/samir-jayaswal.component';
import { SanjayGodboleComponent } from './components/about/team-finbingo/sanjay-godbole/sanjay-godbole.component';
import { SrinivasReddyComponent } from './components/about/team-finbingo/srinivas-reddy/srinivas-reddy.component';
import { SujitBangarComponent } from './components/about/team-finbingo/sujit-bangar/sujit-bangar.component';
import { TeamFinbingoComponent } from './components/about/team-finbingo/team-finbingo.component';
import { VidyasagarPatilComponent } from './components/about/team-finbingo/vidyasagar-patil/vidyasagar-patil.component';
import { WhatIsFinbingoComponent } from './components/about/what-is-finbingo/what-is-finbingo.component';
import { GetStartedComponent } from './components/get-started/get-started.component';
import { HomeComponent } from './components/home/home.component';
import { BlogComponent } from './components/learn/blog/blog.component';
import { ResourcesComponent } from './components/learn/resources/resources.component';
import { FinancialCalculatorsComponent } from './components/offerings/financial-calculators/financial-calculators.component';
import { FinancialLifeGoalsComponent } from './components/offerings/financial-life-goals/financial-life-goals.component';
import { MutualFundsComponent } from './components/offerings/mutual-funds/mutual-funds.component';
import { PortfolioDoctorComponent } from './components/offerings/portfolio-doctor/portfolio-doctor.component';
import { TaxPlanningAdvisorComponent } from './components/offerings/tax-planning-advisor/tax-planning-advisor.component';
import { WealthBuilderComponent } from './components/offerings/wealth-builder/wealth-builder.component';
import { CareersComponent } from './components/others/careers/careers.component';
import { DisclosureComponent } from './components/others/disclosure/disclosure.component';
import { FnbMediaComponent } from './components/others/fnb-media/fnb-media.component';
import { GrievanceComponent } from './components/others/grievance/grievance.component';
import { InvestorCharterComponent } from './components/others/investor-charter/investor-charter.component';
import { PricingComponent } from './components/others/pricing/pricing.component';
import { PrivacyPolicyComponent } from './components/others/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './components/others/terms-and-conditions/terms-and-conditions.component';
import { PartnersComponent } from './components/partners/partners.component';
import { PageNotFoundComponent } from './global/page-not-found/page-not-found.component';
import { PartnerFormComponent } from './modules/partnership/partner-form/partner-form.component';

const routes: Routes = [
  {
    path: '', component: HomeComponent,
    data: {
      "title": "Finbingo | Your 360° Financial Advisor",
      "description": "Finbingo is a Robo advisory and investment platform, helping you in achieving your financial goals and manage your portfolio optimally."
    }
  },

  //Offerings
  {
    path: 'mutual-funds',
    component: MutualFundsComponent,
    data: {
      "title": "Finbingo | Mutual Funds",
      "description": "Finbingo Wealth Builder, a simplified, easy-to-navigate financial planning tool that you can use today to plan for your secure tomorrow."
    }
  },
  {
    path: 'portfolio-doctor',
    component: PortfolioDoctorComponent,
    data: {
      "title": "Portfolio Doctor | Mutual Funds",
      "description": "Finbingo Portfolio Doctor, helps you to invest in mutual funds better and maximize your investment potential."
    }
  },
  {
    path: 'wealth-builder',
    component: WealthBuilderComponent,
    data: {
      "title": "Finbingo | Wealth Builder",
      "description": "Finbingo Wealth Builder, a simplified, easy-to-navigate financial planning tool which will help you in financial planning & securing your tomorrow."
    }
  },
  {
    path: 'tax-planning-advisor',
    component: TaxPlanningAdvisorComponent,
    data: {
      "title": "Finbingo | Tax Planner",
      "description": "With the Finbingo Tax Planner, you can stay on top of your liabilities throughout the year and take the right tax-saving investments in time."
    }
  },
  {
    path: 'financial-life-goals',
    component: FinancialLifeGoalsComponent,
    data: {
      "title": "Finbingo | Financial Life Goals",
      "description": "Finbingo robo-advisor will assist you with your Financial Goal Planning. Simply set your goals and allow the AI-driven tool to start helping you reach them."
    }
  },
  {
    path: 'financial-calculators',
    component: FinancialCalculatorsComponent,
    data: {
      "title": "Finbingo | Financial Calculators",
      "description": "Use our financial calculators to fine-tune your investment planning, compare tax liabilities for different tax regimes, evaluate Financial Life goals, or calculate your Human Life Value"
    }
  },

  //About
  {
    path: 'what-is-finbingo-robo-advisor',
    component: WhatIsFinbingoComponent,
    data: {
      "title": "Finbingo | What is Robo Advisor",
      "description": "Finbingo is a Robo advisory platform that understands your financial goals and helps you in achieving it by helping you manage your portfolio optimally. All analysis is done by computers (and not humans) hence it is called as Robo advisory platform."
    }
  },
  {
    path: 'our-story',
    component: OurStoryComponent,
    data: {
      "title": "Finbingo | Our Story",
      "description": "Finbingo emerged as a natural progression of decades of our experience working with and dealing with thousands of financial advisors."
    }
  },
  {
    path: 'team-finbingo',
    component: TeamFinbingoComponent,
    data: {
      "title": "Finbingo | Leadership Team",
      "description": "More about Leadership Team at Finbingo"
    }
  },
  {
    path: 'samir-jayaswal',
    component: SamirJayaswalComponent,
    data: {
      "title": "Finbingo | Leadership Team",
      "description": "More about Leadership Team at Finbingo"
    }
  },
  {
    path: 'atul-rege',
    component: AtulRegeComponent,
    data: {
      "title": "Finbingo | Leadership Team",
      "description": "More about Leadership Team at Finbingo"
    }
  },
  {
    path: 'sujit-bangar',
    component: SujitBangarComponent,
    data: {
      "title": "Finbingo | Leadership Team",
      "description": "More about Leadership Team at Finbingo"
    }
  },
  {
    path: 'srinivas-reddy',
    component: SrinivasReddyComponent,
    data: {
      "title": "Finbingo | Leadership Team",
      "description": "More about Leadership Team at Finbingo"
    }
  },
  {
    path: 'vidyasagar-patil',
    component: VidyasagarPatilComponent,
    data: {
      "title": "Finbingo | Leadership Team",
      "description": "More about Leadership Team at Finbingo"
    }
  },
  {
    path: 'sanjay-godbole',
    component: SanjayGodboleComponent,
    data: {
      "title": "Finbingo | Leadership Team",
      "description": "More about Leadership Team at Finbingo"
    }
  },
  {
    path: 'contact',
    component: ContactUsComponent,
    data: {
      "title": "Finbingo | Contact Us",
      "description": "Contact us at +91 93219 08752 and we will help you get started with your Healthy Financial journey"
    }
  },

  //Learn
  {
    path: 'resources',
    component: ResourcesComponent,
    data: {
      "title": "Finbingo | Resources",
      "description": "Resources"
    }
  },
  {
    path: 'blog',
    component: BlogComponent,
    data: {
      "title": "Finbingo | Blog",
      "description": "Blog"
    }
  },

  // Others
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
    data: {
      "title": "",
      "description": ""
    }
  },
  {
    path: 'pricing',
    component: PricingComponent,
    data: {
      "title": "Finbingo | Pricing",
      "description": "Pricing"
    }
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      "title": "",
      "description": ""
    }
  },
  {
    path: 'careers',
    component: CareersComponent,
    data: {
      "title": "Finbingo | Careers",
      "description": "Join Finbingo Team"
    }
  },
  {
    path: 'disclosure',
    component: DisclosureComponent,
    data: {
      "title": "",
      "description": ""
    }
  },
  {
    path: 'investor-charter',
    component: InvestorCharterComponent,
    data: {
      "title": "",
      "description": ""
    }
  },
  {
    path: 'grievance',
    component: GrievanceComponent,
    data: {
      "title": "",
      "description": ""
    }
  },
  {
    path: 'media',
    component: FnbMediaComponent,
    data: {
      "title": "Finbingo | Media",
      "description": "Finbingo in media"
    }
  },
  //SAGAR
  // {  
  //   path: 'partners',
  //   component: PartnersComponent,
  //   data: {
  //     "title": "Finbingo | Partners",
  //     "description": ""
  //   }
  // },
  //Get Started
  {
    path: 'get-started',
    component: GetStartedComponent,
    data: {
      "title": "Finbingo | Get Started",
      "description": "Finbingo has offering which will suit the needs of every investor. Select one and make the most of your investments."
    }
  },
  { path: 'amc', loadChildren: () => import('./components/amc/amc.module').then(m => m.AmcModule) },
  { path: 'nps', loadChildren: () => import('./components/nps/nps.module').then(m => m.NpsModule) },
  { path: 'partners', loadChildren: () => import('./modules/partnership/partnership.module').then(m => m.PartnerShipModule) },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  },
  { path: 'campaign', loadChildren: () => import('./components/landing-pages/landing-pages.module').then(m => m.LandingPagesModule) },
  {
    path: '**',
    redirectTo: '/page-not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
