<section class="homepage-blocks">
  <div class="container finbWbBanner">
    <div class="row text-center justify-content-center mb-2 pb-2">
      <div class="col-lg-10">
        <!-- <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/NEW-MAIN-IMG3.jpg" width="100%" alt=""> -->
        <h1 class="page-bigtitle">Contact Finbingo</h1>
        <h1 class="page-bigtitle orange">Get in touch</h1>
        <p>
          <strong>We would love to hear from you!</strong>
        </p>
      </div>
    </div>
  </div>
</section>
<section class="mb-5">
  <div class="container">
    <div class="contact-form-wrap">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <input type="text" placeholder="Your First Name" formControlName="firstName"
                    class="form-control text-capitalize"
                    [ngClass]="{ 'is-invalid': f.firstName.touched && f.firstName.errors }" autocomplete="off">
                  <div *ngIf="f.firstName.touched && f.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors.required">First Name is required</div>
                    <div *ngIf="f.firstName.errors.pattern">Please enter valid details.</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <input type="text" placeholder="Your Last Name" formControlName="lastName"
                    class="form-control text-capitalize"
                    [ngClass]="{ 'is-invalid': f.lastName.touched && f.lastName.errors }" autocomplete="off">
                  <div *ngIf="f.lastName.touched && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                    <div *ngIf="f.lastName.errors.pattern">Please enter valid details.</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <input type="text" placeholder="Your Email Address" formControlName="email" class="form-control"
                    [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors }" autocomplete="off">
                  <div *ngIf="f.email.touched && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required.</div>
                    <div *ngIf="f.email.errors.pattern">Please enter valid details.</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <input type="text" placeholder="Your Phone Number" formControlName="phoneNumber" class="form-control"
                    maxlength="10" (keypress)="cmService.numericOnly($event)"
                    [ngClass]="{ 'is-invalid': f.phoneNumber.touched && f.phoneNumber.errors }" autocomplete="off"
                    inputmode="numeric">
                  <div *ngIf="f.phoneNumber.touched && f.phoneNumber.errors" class="invalid-feedback">
                    <div *ngIf="f.phoneNumber.errors.required">Phone Number is required</div>
                    <div *ngIf="f.phoneNumber.errors.pattern">Please enter valid details.</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <input type="text" placeholder="Subject" formControlName="subject" class="form-control"
                    [ngClass]="{ 'is-invalid': f.subject.touched && f.subject.errors }" autocomplete="off">
                  <div *ngIf="f.subject.touched && f.subject.errors" class="invalid-feedback">
                    <div *ngIf="f.subject.errors.required">Subject is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <textarea type="text" placeholder="Description" formControlName="description" class="form-control"
                    [ngClass]="{ 'is-invalid': f.description.touched && f.description.errors }"
                    autocomplete="off"></textarea>
                  <div *ngIf="f.description.touched && f.description.errors" class="invalid-feedback">
                    <div *ngIf="f.description.errors.required">Description is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-3 mb-2">
                      <button class="btn btn-orangehomepage"
                        (click)="cmService.checkFormValidity(contactForm)">{{isSubmitted ? 'Submitting' : 'Submit'}}</button>
                    </div>
                    <div class="col-md-9">
                      <div *ngIf="alert.message">
                        <div class="alert alert-dismissible fade show" role="alert"
                          [ngClass]="alert.type ? 'alert-success': 'alert-danger'">
                          {{alert.message}}
                          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="text-center social-contact m-4">
      <div class="social-contact-list">
        <a href="https://www.linkedin.com/company/finbingo" target="_blank">
          <i class="fab fa-linkedin"></i>
        </a>
        <a href="https://twitter.com/MyFinBingo" target="_blank">
          <i class="fab fa-twitter-square"></i>
        </a>
        <a href="https://www.instagram.com/finbingo/" target="_blank">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="https://www.facebook.com/FinBingo" target="_blank">
          <i class="fab fa-facebook-square"></i>
        </a>
      </div>
    </div>
    <div class="office-contact">
      <div class="office-contact-box office-redbox">
        <h4>Head Office</h4>
        <p>
          #1309, Lodha Supremus,
          <br>
          Saki Vihar Road, Mumbai,
          <br>
          India 400 072
        </p>
      </div>
      <div class="office-contact-box office-yellowbox">
        <h4>WhatsApp No</h4>
        <p><strong>
            <a href="https://wa.me/919321908752" target="_blank">+91 93219 08752</a>
          </strong></p>
        <p class="invisible"><strong>
            <a href="tel:+91 22 4543 3900">+91 22 4543 3900</a>
          </strong></p>
      </div>
      <div class="office-contact-box office-greenbox">
        <h4>EMAILS</h4>
        <p><strong>
            <a href="mailto:care@finbingo.com">Care@finbingo.com</a>
          </strong></p>
        <p><strong>
            <a href="mailto:hr@finbingo.com">hr@finbingo.com</a>
          </strong></p>
      </div>
    </div>
  </div>
</section>
<section class="homepage-blocks">
  <div class="container">
    <div class="finb-mygoals">
      <h4>Get Started Today!</h4>
      <br>
      <p>
        <strong>The best time to begin investing in your financial future was yesterday; the next best is
          today!</strong>
      </p>
      <p>
        We have a set of wealth building and tax planning tools that can assist you in preparing for your future.
      </p>
      <div class="text-center">
        <a href="" class="btn btn-orangehomepage">Get Started</a>
      </div>
    </div>
  </div>
</section>
