<section class="manager-descriptionwrap">
  <div class="container">
    <div class="manager-img">
      <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/sanjay-godbole-finbingo.jpg" alt="Sanjay Godbole" title="Sanjay Godbole">
    </div>
    <h4>Sanjay Godbole</h4>
    <h5>Director</h5>
    <div class="manager-social">
      <a href="https://www.linkedin.com/in/sanjaygodbole/" target="_blank">
        <i class="fab fa-linkedin"></i>
      </a>
      <a href="https://twitter.com/SanjayGodbole" target="_blank">
        <i class="fab fa-twitter-square"></i>
      </a>
      <!-- <a href="javascript:void(0);">
        <i class="fab fa-instagram"></i>
      </a> -->
      <a href="https://www.facebook.com/sanjay.godbole" target="_blank">
        <i class="fab fa-facebook-square"></i>
      </a>
    </div>
    <div class="manager-description">
      <p>
        A metallurgical engineering graduate from NIT Nagpur, Sanjay moved to Pune to complete his Post Graduate Diploma in
        Advanced Computing from C-DAC.
      </p>
      <p>
        Sanjay brings more than 20 years of rich industry experience to the table. His various responsibilities over the years
        have included leading product & application development, service delivery, and program management for global businesses.
        It is his proven abilities that have enabled Sanjay to hold various senior-level positions at renowned organisations
        such as Infosys and Zensar Technologies where he was involved in building high performance teams, conceptualizing
        industry solutions and driving growth.
      </p>
      <p>
        Sanjay is an effective communicator with the added authority of excellent relationship management skills. Through his
        career graph, Sanjay has been associated with people at various levels of business and management, including
        multi-cultural teams across geographies.
      </p>
      <p>
        Before joining SSBA Innovations, he served as the Founding Director at ITARIUM Technologies, an IT start-up in Pune that
        works in the development of Fintech products and services. Prior to that, Sanjay worked at Infosys as a Senior Program
        Manager responsible for product delivery of their flagship products in the area of process automation in Europe and the
        Asia-Pac region.
      </p>
      <p>
        Sanjay’s strength is in his thorough understanding and experience of dual shore execution and hands-on experience in
        end-to-end program management. He has executed programs with varied responsibilities like client interfacing, pre-sales
        support, strategy building, business consulting and service delivery management across various domains including banking
        & finance, retail, aviation and learning.
      </p>
      <p>
        When he is not cracking strategy within the confines of an office, Sanjay likes to spend time in the wilderness
        indulging his serious hobby of nature photography.
      </p>
      <a href="javascript:void(0);" routerLink="/team-finbingo" class="btn btn-primary">Back to Team Finbingo</a>
      <br>
      <br>
    </div>
  </div>
</section>