<section class="homepage-blocks">
  <div class="container finbWbBanner">
    <div class="row text-center justify-content-center mb-5">
      <div class="col-lg-10 col-xl-8">
        <!-- <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/NEW-MAIN-IMG3.jpg" width="100%" alt=""> -->
        <h1 class="page-bigtitle">Finbingo Wealth Builder</h1>
        <h1 class="page-bigtitle orange">Act today to plan for your tomorrow</h1>
        <p>
          The financial decisions that you take today are what will help you achieve a wealth corpus when you retire.
          Our AI-driven, customized tool will take the guesswork out of your financial planning.
        </p>

        <div class="text-center">
          <a [href]="cmService.portalURL + '/grow-wealth/'" class="btn btn-orangehomepage">Get Started</a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="homepage-blocks">
  <div class="container">
    <div class="finb-wbtpa-module">
      <div>
        <h5>Why Finbingo Wealth Builder</h5>
        <p>Don’t leave your financial future to the whims of individuals with unconscious biases and preferences.</p>
      </div>
      <div>
        <h5>How it works</h5>
        <p>We use your current personal, professional and investment data to offer you customized financial advice.</p>
      </div>
      <div>
        <h5>What you get</h5>
        <p>Experience unbiased, expert, AI-driven financial advice today, to plan for a better financial tomorrow.</p>
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks">
  <div class="container">
    <div class="why-finbingo-wb finbWhitebox">
      <div class="finb-feature-content">
        <h4>Why Finbingo Wealth Builder?</h4>
        <p>
          Why leave your wealth building to financial advisors with unknown, inbuilt biases? Why invest in products that
          are based on commissions?
        </p>
        <p>
          Instead, turn to Finbingo Wealth Builder, a simplified, easy-to-navigate financial planning tool that you can
          use today to plan for your secure tomorrow. Take advantage of our AI-driven insights and take the guesswork
          out of your financial goals. Put your trust in Finbingo Wealth Builder and transform your financial strength.
          Building a corpus of funds for your future – immediate and long term – was never this easy.
        </p>
        <div class="sound-financiallife">
          <div>
            <p>
              <strong>
                A sound financial life awaits you
              </strong>
            </p>
            <p>
              When you partner with Finbingo Wealth Builder, you are partnering with a team of financial experts who
              have only your financial independence in mind. We put your interests first, always. And everything that we
              undertake is with the aim of protecting and furthering those interests.
            </p>
            <p>
              You can expect to receive:
            </p>
            <ul class="soundlifelist">
              <li>
                – Full financial planning
              </li>
              <li>
                – Personalized portfolio analysis
              </li>
              <li>
                – Avenues to grow your wealth
              </li>
              <li>
                – Fiduciary advice with transparency
              </li>
            </ul>
          </div>
          <img
            src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/finbingo-wealthbuilder-2.png"
            width="100%" alt="Wealth Builder" title="Wealth Builder">
        </div>
        <p>
          Whether you are a novice investor looking for guidance, or a seasoned player looking for the best online
          solution, Wealth Builder will take the task off your hands, leaving you free to do what you do best – live
          life to the fullest. Allow our robo-advisor to build and manage your portfolio, so you don’t have to.
        </p>
        <p>
          <strong>Are you ready to get started?</strong>
        </p>
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks">
  <div class="container">
    <div class="why-finbingo-wb finbWhitebox">
      <div class="finb-feature-content">
        <h4>How it works?</h4>

        <div class="how-works-timeline">
          <div class="hw-timeline-content">
            <div class="hw-timeline-number yellow-circle">
              <div class="hw-timeline-circle">
                <p>1</p>
              </div>
            </div>
            <div class="hw-timeline-text">
              <div class="hw-text">
                <div class="">
                  <h5><span>Input investment data</span></h5>
                </div>
              </div>
            </div>
          </div>
          <div class="hw-timeline-content">
            <div class="hw-timeline-number green-circle">
              <div class="hw-timeline-circle">
                <p>2</p>
              </div>
            </div>
            <div class="hw-timeline-text">
              <div class="hw-text">
                <div class="">
                  <h5 class=""><span>Set your financial life goals</span></h5>
                </div>
              </div>
            </div>
          </div>
          <div class="hw-timeline-content">
            <div class="hw-timeline-number orange-circle">
              <div class="hw-timeline-circle">
                <p>3</p>
              </div>
            </div>
            <div class="hw-timeline-text">
              <div class="hw-text">
                <div class="">
                  <h5 class=""><span>Find out where you stand</span></h5>
                </div>
              </div>
            </div>
          </div>
          <div class="hw-timeline-content">
            <div class="hw-timeline-number blue-circle">
              <div class="hw-timeline-circle">
                <p>4</p>
              </div>
            </div>
            <div class="hw-timeline-text">
              <div class="hw-text">
                <div class="">
                  <h5 class=""><span>Get your customized report</span></h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p>
          <strong>Wealth Builder works in different ways for different people.</strong> <br>
          That is what allows us to offer customized and nuanced financial services to every individual. However, the
          way we begin is the same for all.
        </p>
        <p><strong>We start by getting to know you.</strong></p>
        <div class="sound-financiallife">
          <div>
            <p>
              <strong> <i>Personal</i> </strong>
            </p>
            <p>
              We’ll begin by asking about your personal life; the usual – age, family, dependents, job details and your
              risk appetite. This information sets the foundation for us to assess your financial capability as well as
              desire to take risks in financial planning.
            </p>
            <p>
              <strong>
                Assets & Liabilities
              </strong>
            </p>
            <p>
              Next we move on to your current financial life. Together we will put down all your assets and liabilities
              till date, as well as any insurance and pension plans that you may already have.
            </p>
          </div>
          <img
            src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/finbingo-wealthbuilder-2.png"
            width="100%" alt="Wealth Builder" title="Wealth Builder">
        </div>
        <p><strong><i>Income & Expenses</i></strong></p>
        <p>
          Perhaps you hold a job or are self-employed. To be able to effectively compute your financial strategy, we
          will require you to fill in your inflow and outflow, essentially your monthly income and expenses. It need not
          be accurate; you can put an approximate figure for us to get a better idea.
        </p>
        <p>
          <strong><i>Analysis</i></strong>
        </p>
        <p>
          And now we analyse. The analysis will tell you where you stand with regard to your financial wealth. What is
          the quality of your investments? We will help you understand your networth keeping in mind inflation rates.
          You can study the analysis to absorb our recommendations – the quick action and the long-term action steps
          that you need to take today to be ready for tomorrow.
        </p>
        <p>
          <strong>We’ll help you build your portfolio.</strong>
          <br>
          Now that we know you better, we will use our AI tools to recommend the ideal investing plan, keeping in mind
          your need for emergency funds, risk tolerance and future milestones. What you get is unbiased, objective and
          yet customized expert advice to help you build a portfolio that will stand by you as you strive to reach your
          financial goals. You can adjust and change this personalized portfolio any time you wish
        </p>
        <div class="sound-financiallife">
          <div>
            <p>
              <strong> That’s not all. </strong>
            </p>
            <p>
              Our service doesn’t end here. Once your portfolio is in place, we will continue to monitor and manage your
              investment using our intelligent robo-advisor. We continually track thousands of investment opportunities
              to offer you only the best investment vehicles to fulfill your financial goals. It is this ongoing advice
              that will keep you on track.
            </p>
            <p>
              Whether you wish to rebalance your portfolio or relocate some assets to reduce taxes, it is all automated
              and possible from the comfort of your laptop. All your wealth is visible in one place for you to enjoy a
              better, easier and fully-automated investing experience.
            </p>
            <p>
              Make the most of your money and get on the road to complete financial security.
            </p>
          </div>
          <img
            src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/finbingo-wealthbuilder-4.png"
            width="100%" alt="Wealth Builder" title="Wealth Builder">
        </div>
      </div>
    </div>
  </div>
</section>


<section class="homepage-blocks">
  <div class="container">
    <div class="finb-mygoals">
      <h4>What You Get</h4>
      <br>
      <p>
        We put your interest first. Always. We cannot stress on that more. Everything that we do is geared towards
        helping you make the most of your money, and your life.
      </p>
      <ul class="what-you-get">
        <li>
          <i class="far fa-arrow-right"></i>
          <h4>Customised financial advice</h4>
          <p>
            Based on your current financial status, risk profile and life goals, the AI-driven, intelligent robo-advisor
            will recommend the right amount to invest and in the right vehicle. This customized, unbiased asset
            allocation varies from individual to individual. If you wish, you can even change this allocation as you see
            fit.
          </p>
        </li>
        <li>
          <i class="far fa-arrow-right"></i>
          <h4>No hidden charges</h4>
          <p>
            With Wealth Builder, there are no hidden charges or fees. Everything is upfront and declared. No transfer
            fees and no additional charges either. All you do is pay a one-time annual fee, irrespective of the size of
            your investment. This is what sets us apart from the many players in the investment marketplace today.
          </p>
        </li>
      </ul>
      <ul class="what-you-get">
        <li>
          <i class="far fa-arrow-right"></i>
          <h4>Best-in-class financial portfolio</h4>
          <p>
            Our strong research team relies on the best technologies and tracks thousands of funds, assets and
            investment opportunities to offer you the cream of the financial market.
          </p>
        </li>
        <li>
          <i class="far fa-arrow-right"></i>
          <h4>Everything in one place</h4>
          <p>
            All your financial accounts, whether it is your investments or your liabilities, are in one place, giving
            you a single snapshot of your money and financial position in a snap.
          </p>
        </li>
      </ul>
      <ul class="what-you-get">
        <li>
          <i class="far fa-arrow-right"></i>
          <h4>Download Financial Document</h4>
          <p>
            Once you register, you will get a comprehensive financial document which you can download for your
            reference.
          </p>
        </li>
        <li>
          <i class="far fa-arrow-right"></i>
          <h4>Reliable customer service</h4>
          <p>
            A strong response cell is available during working hours to answer all your queries or concerns.
          </p>
        </li>
      </ul>
    </div>
  </div>
</section>
<section class="homepage-blocks">
  <div class="container">
    <div class="finb-mygoals">
      <h4>Get Started Today!</h4>
      <br>
      <p>
        <strong>The best time to begin investing in your financial future was yesterday; the next best is
          today!</strong>
      </p>
      <p>
        We have a set of wealth building and tax planning tools that can assist you in preparing for your future.
      </p>
      <div class="text-center">
        <a [href]="cmService.portalURL + '/grow-wealth/'" class="btn btn-orangehomepage">Get Started</a>
      </div>
    </div>
  </div>
</section>
<br>
<br>
<br>
