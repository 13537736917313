import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  constructor(public cmService: CommonService, private dataService: DataService, private router: Router) { }

  ngOnInit(): void {

    this.getPlans();
  }
  subPlans = [];
  getPlans() {
    this.dataService.getAPICall(environment.USER, 'v2/subscriptions/masterplans')
      .subscribe(result => {
        if (result.success) {
          this.subPlans = result.data.plans;
        }
      })
  }

  showModalBox;
  modalItem;

  closeModal() {
    this.modalItem = null;
  }

  open(item) {
    this.modalItem = null;
    if (0) {
      // Dont open the modal
      this.showModalBox = false;
    } else {
      // Open the modal
      this.showModalBox = true;
      this.modalItem = item
    }
  }

}
