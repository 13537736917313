<section class="homepage-blocks">
  <div class="container finbWbBanner">
    <div class="row text-center justify-content-center mb-2 pb-2">
      <div class="col-lg-10">
        <p>
          <strong>
            Please wait redirecting...
          </strong>
        </p>
      </div>
    </div>
  </div>
</section>
