<section class="homepage-blocks">
  <div class="container finbWbBanner">
    <div class="row text-center justify-content-center mb-5">
      <div class="col-lg-10">
        <!-- <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/NEW-MAIN-IMG3.jpg" width="100%" alt=""> -->
        <h1 class="page-bigtitle">Finbingo Robo Advisor</h1>
        <h1 class="page-bigtitle orange">Wealth Analysis and Advisory, Simplified.</h1>
        <p>
          <strong>Finbingo is one of the most intelligent robo-advisors in India.</strong>
        </p>
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks">
  <div class="container">
    <div class="why-finbingo-wb finbWhitebox">
      <div class="finb-feature-content">
        <!-- <h4>What is Finbingo?</h4>
        <p>
          Did you know that in FY 2018-19 alone, the number of individual ITRs filed till 31st August stood at 54.2
          million? And this number is growing every year as more salaried individuals join the workplace, and more
          professionals and small businesses come under the tax bracket.
        </p> -->
        <div class="sound-financiallife how-robo-works">
          <div>
            <p>
              <strong>
                What is Finbingo?
              </strong>
            </p>
            <p>
              Finbingo is a Robo advisory platform that understands your financial goals and helps you in achieving it by helping you
              manage your portfolio optimally. All analysis is done by computers (and not humans) hence it is called as Robo advisory
              platform.
            </p>
            <p>
              It does this by understanding and further analyzing your
              1. Assets <br>
              2. Liabilities <br>
              3. Income <br>
              4. Expenses <br>
              5. Liquidity <br>
              6. Risk taking ability etc
            </p>
            <p>
              It is a true 360-degree financial planning and wealth building platform that takes a dispassionate and scientific approach in analyzing all the above and suggesting financial investments that are most suited for you in meeting your life goals.
            </p>
            <p>
              It also checks your current portfolio composition and takes it through a variety of tests to understand if its performance is optimal. It helps you in rebalancing your portfolio if the existing portfolio is performing sub optimally so that you have better chances of meeting your goals.
            </p>
            <p>
              It provides suggestions across a number of asset classes raging from fixed income securities, mutual funds, exchange traded funds, precious metals and insurance.
            </p>
            <p>
              <strong>What is a robo-advisor?</strong>
            </p>
            <p>
              Simply put, Robo-advisor is a technology platform that provides digital, algorithm-driven financial analysis and
              advisory with little to no human supervision. Investors make emotional decisions at market highs and market lows and
              based on gut feelings. Software does not make these kinds of mistakes. Robo-advisor collects information from users
              about their financial landscape and life goals through an online forms and then analyses the data to offer unbiased
              financial advice and investment strategies. Additionally, robo-advisor also helps visualize projections and growth in
              the existing assets and investments. An important aspect is that because no human beings are involved, robo advisors can
              scan through millions of data points to arrive at suggestions. This makes the process whole lot cheaper and you can
              repeat calculations as many times as you want.
            </p>
          </div>
          <div>
            <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/NEW-MAIN-IMG3.jpg" width="100%" alt="Robo Advisor" title="Robo Advisor" style="margin-bottom: 30px;">
            <p>
              <strong>
                – How does Finbingo Robo-Advisor work?
              </strong>
            </p>
            <ul>
              <li>
                <p>
                  With Finbingo Wealth Builder, all you need to do is fill in a simple questionnaire, giving us a peek into your current
                  financial position, your risk appetite and your future financial goals.
                </p>
              </li>
              <li>
                <p>
                  Finbingo robo-advisor uses its pre-programmed algorithm to recommend a financial portfolio that matches your investment
                  profile, risk score and future needs.
                </p>
              </li>
              <li>
                <p>
                  The resultant expert advice is based on your financial situation, your risk appetite, goals and the current state of
                  financial markets continuously monitored by Finbingo Market analysts.
                </p>
              </li>
              <li>
                <p>
                  You can access Finbingo anytime to perform “what-if” analysis based on investment changes or monitor and continue
                  working to get closer to your financial goals.
                </p>
              </li>
              <li>
                <p>
                  Finbingo utilizes its algorithms to automatically allocate, manage and optimize your assets, doing away with the need
                  for a human advisor.
                </p>
              </li>
            </ul>
            <p>
              <strong>– Why are Robo-Advisors so popular?</strong>
            </p>
            <ul>
              <li>
                <p>
                  The main advantage of robo-advisors is that they are low-cost alternatives to traditional advisors.
                </p>
              </li>
              <li>
                <p>
                  Robo-advisor is a great tool for early investors, young professionals who want to start early and get unbiased and wise
                  investment strategy.
                </p>
              </li>
              <li>
                <p>
                  Best suited for do-it-yourself investors. Robo-advisor is easy to access and available 24/7, which enables unlimited
                  “what-if” analysis.
                </p>
              </li>
              <li>
                <p>
                  Contrary to human financial advisors who mandate a large minimum investment, investors can start using robo-advisor with
                  very minimum investment corpus.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <p>
          <strong>
            Why choose Finbingo?
          </strong>
        </p>
        <p>
          Finbingo, one of the most intelligent robo-advisors in India, does the following:
        </p>
        <p>
          <strong>Eliminates bias –</strong> Robo-advisors are not biased. They go strictly by data. Being machine driven, there is no place for
          emotions and gut feelings. They need to see hard data to be convinced of performance. And it augurs well for the
          investor.
        </p>
        <p>
          <strong>Inculcates discipline –</strong> Discipline is the most important trait needed to succeed in investment and the financial world.
          Humans can be undisciplined and can falter during times of market stress; machines will not.
        </p>
        <p>
          <strong>Scientific approach –</strong> They take a scientific approach. Investment models are coded in software and each time they are
          presented with an investment opportunity, they go about analyzing these opportunities scientifically, using time-tested
          models
        </p>
        <p>
          <strong>Spotting opportunities –</strong> Robo-advisors go through millions of data points for you, everyday, looking for both,
          investment opportunities and red flags. They are much better in spotting investment opportunities and problems quickly,
          before anyone else does.
        </p>
        <p>
          <strong>Quarterly reviews –</strong> Investment agencies typically sell you a financial product but don’t periodically review it with
          you, leaving you to fend for yourself. At Finbingo, one of the ways we effectively use the robo-advisor platform is to
          review your investment portfolio every quarter, calling you for action,if required.
        </p>
        <p>
          <strong>Reaches out to every type of investor –</strong> In a country like India, people who do not have big portfolios don’t get good
          advice. Most financial advisors require that the investor have a minimum amount in their portfolio to ‘qualify’ for
          their advice. Finbingo is changing this. Technology helps us reduce the cost of dispensing advice. We can hence provide
          advice to millions of investors, regardless of their investment size and profile. Someone investing only Rs 100 will
          also go through the same process and benefit from the same quality of advice as someone investing Rs 1 Crore.
        </p>
      </div>
    </div>
  </div>
</section>
<br>
<br>
<br>