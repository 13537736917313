import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Location } from '@angular/common';


if (environment.production) {
  enableProdMode();
  // Hotjar Tracking Code 
  const HotJarScript = document.createElement('script');
  HotJarScript.innerHTML = `
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:1632249,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
  document.head.appendChild(HotJarScript);
}

// For adding trailing slash
// const __stripTrailingSlash = (Location as any).stripTrailingSlash;
// (Location as any).stripTrailingSlash = function _stripTrailingSlash(url: string): string {
//   const queryString$ = url.match(/([^?]*)?(.*)/);
//   if (queryString$[2].length > 0) {
//     return /[^\/]\/$/.test(queryString$[1]) ? queryString$[1] + '.' + queryString$[2] : __stripTrailingSlash(url);
//   }
//   return /[^\/]\/$/.test(url) ? url + '.' : __stripTrailingSlash(url);
// };

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/ngsw-worker.js')
      console.log('Registered as service worker');
    }
  })
  .catch(err => console.error(err));
});
