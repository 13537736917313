import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sujit-bangar',
  templateUrl: './sujit-bangar.component.html',
  styleUrls: ['./sujit-bangar.component.css']
})
export class SujitBangarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
