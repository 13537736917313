import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-financial-life-goals',
  templateUrl: './financial-life-goals.component.html',
  styleUrls: ['./financial-life-goals.component.css']
})
export class FinancialLifeGoalsComponent implements OnInit {

  constructor(public cmService: CommonService) { }

  ngOnInit(): void {
  }

}
