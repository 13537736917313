import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import AOS from 'aos';

@Component({
  selector: 'app-tax-planning-advisor',
  templateUrl: './tax-planning-advisor.component.html',
  styleUrls: ['./tax-planning-advisor.component.css']
})
export class TaxPlanningAdvisorComponent implements OnInit {

  constructor(public cmService: CommonService) { }

  ngOnInit(): void {
    AOS.init();
  }

}
