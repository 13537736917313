<section class="homepage-blocks">
  <div class="container finbWbBanner">
    <ng-container *ngIf="isBlogUrl; else elseTemplate">
      <div class="row text-center justify-content-center mb-2 pb-2">
        <div class="col-lg-10">
          <p>
            <strong>
              Please wait redirecting...
            </strong>
          </p>
        </div>
      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <div class="row">
        <div class="col-md-12">
          <div class="error-template">
            <h1>
              Oops!</h1>
            <h2>
              404 Not Found
            </h2>
            <div class="error-details">
              Sorry, an error has occured, Requested page not found!
            </div>
            <div class="error-actions">
              <a routerLink='/' class="btn btn-primary"><i class="fa fa-home"></i>
                Take Me Home </a>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</section>
