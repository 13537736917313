<section class="manager-descriptionwrap">
  <div class="container">
    <div class="manager-img">
      <img
        src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/atul-rege-finbingo.jpg"
        alt="Atul Rege" title="Atul Rege">
    </div>
    <h4>Atul Rege</h4>
    <h5>Director</h5>
    <div class="manager-social">
      <a href="https://www.linkedin.com/in/atul-rege-25628434/" target="_blank">
        <i class="fab fa-linkedin"></i>
      </a>
      <!-- <a href="javascript:void(0);">
        <i class="fab fa-twitter-square"></i>
      </a>
      <a href="javascript:void(0);">
        <i class="fab fa-instagram"></i>
      </a> -->
      <a href="https://www.facebook.com/atul.rege.121" target="_blank">
        <i class="fab fa-facebook-square"></i>
      </a>
    </div>
    <div class="manager-description">
      <p>
        Atul is a first-generation entrepreneur who has spent over twenty-five years in the financial services industry
        and
        related technology services.
      </p>
      <p>
        Today, as a founder member of SSBA, Atul is involved in every aspect of SSBA and its innovative products. From
        early
        stage ideation to finding and recruiting the top technology talent that powers SSBA’s unique product platform,
        he has
        been at the forefront of every major decision taken at SSBA.
      </p>
      <p>
        His journey began as an office assistant at B J Shah & Co, and within two years, his honesty, integrity, and
        hard work
        brought to him an opportunity to start his own sub-broking business.
      </p>
      <p>
        Atul believes that technology is the key driver for growth and change, and he integrated it in his business from
        the
        very beginning. With this technology focus and his unrelenting passion for the financial services industry, Atul
        took
        his brokerage business from its humble beginnings to become a full service brokerage house that services retail,
        HNIs
        and corporates. In the process he witnessed several market cycles, various technology changes, and used every
        opportunity to work with the brightest people in the industry.
      </p>
      <p>
        Today Atul considers it a privilege to be working alongside like-minded people of SSBA on the two technology
        platforms
        that he thinks will revolutionize the process of income tax return filing and wealth creation.
      </p>
      <p>
        Atul is a true-blooded Mumbaikar, and he continues to live in his most favorite city in the world with his wife,
        two
        kids, and his Labrador, Leo. When not working with technology, Atul enjoys travelling, philanthropy and long
        walks with
        Leo.
      </p>
      <a href="javascript:void(0);" routerLink="/team-finbingo" class="btn btn-primary">Back to Team Finbingo</a>
      <br>
      <br>
    </div>
  </div>
</section>