import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MutualFundsComponent } from './mutual-funds/mutual-funds.component';
import { WealthBuilderComponent } from './wealth-builder/wealth-builder.component';
import { TaxPlanningAdvisorComponent } from './tax-planning-advisor/tax-planning-advisor.component';
import { FinancialLifeGoalsComponent } from './financial-life-goals/financial-life-goals.component';
import { FinancialCalculatorsComponent } from './financial-calculators/financial-calculators.component';
import { PortfolioDoctorComponent } from './portfolio-doctor/portfolio-doctor.component';


@NgModule({
  declarations: [MutualFundsComponent, WealthBuilderComponent, TaxPlanningAdvisorComponent, FinancialLifeGoalsComponent, FinancialCalculatorsComponent, PortfolioDoctorComponent],
  imports: [
    CommonModule
  ]
})
export class OfferingsModule { }
