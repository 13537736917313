import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  constructor(fb: FormBuilder, private dataService: DataService, public cmService: CommonService) {

    this.contactForm = fb.group({
      firstName: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ']+$/)]),
      lastName: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z ']+$/)]),
      email: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]),
      phoneNumber: new FormControl('', [Validators.required, Validators.pattern('[1-9]\\d{9}')]),
      subject: new FormControl('', [Validators.required]),
      description: new FormControl('', Validators.required),
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.contactForm.controls; }

  contactJSON:ContactJSON;
  contactForm: FormGroup;

  ngOnInit(): void {

  }

  alert = {
    message: "",
    type: false
  };

  showAlert(msg, type = true) {
    this.alert.message = msg;
    this.alert.type = type;
  }

  isSubmitted;
  onSubmit() {
    this.contactJSON = {
      fullName: this.textCapitalize(this.f.firstName.value.trim()) + ' ' + this.textCapitalize(this.f.lastName.value.trim()),
      email: this.f.email.value,
      phoneNumber: this.f.phoneNumber.value,
      subject: this.f.subject.value.trim(),
      description: this.f.description.value.trim()
    }
    this.isSubmitted = true;
    this.dataService.postAPICall(environment.USER, "v1/portal/query", this.contactJSON)
      .subscribe(result => {
        if (result.success) {
          this.showAlert("Request submitted successfully, we'll get back to you soon");
          this.contactForm.reset();
          this.isSubmitted = false;
        } else {
          this.showAlert("Something went wrong, please try after some time", false);
          this.isSubmitted = false;
        }
      })
  };

  textCapitalize(string:string){
    return string.replace(/\b\w/g, l => l.toUpperCase())
  }


}

interface ContactJSON {
  fullName: string,
  email: string,
  phoneNumber: number,
  subject: string,
  description: string
}
