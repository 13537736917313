<section class="homepage-blocks">
  <div class="container">
    <div class="row">  
      <div class="col-lg-7 col-md-7 col-sm-12 col-12 spc">
        <!-- <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/NEW-MAIN-IMG3.jpg" width="100%" alt=""> -->
        <h1 class="page-bigtitle txBg">India’s Most Accurate</h1>
        <h1 class="page-bigtitle orange txBg1">Tax Planner</h1>
       
          <div class="txSz">Saves every last Rupee</div>
      
        <div class="">
          <a [href]="cmService.portalURL + '/save-tax'" class="btn btn-orangehomepage btnTx">Get Started</a>
        </div>
      </div>
      <div class="col-lg-5 col-md-5 col-sm-12 col-12">
        <img class="mobWid" src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/fin_process%2B.gif.gif" width="100%" alt="">       
      </div>    
  </div>
</div>
</section>

<section class="homepage-blocks mvTp">
  <div class="container">
    <div class="finb-mygoals">
      <!-- <h4>What You Get</h4> -->
      
      <div class="box">
        <div class="container">
           <div class="row">

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" >   
              <h4 class="TpTx">What You Get</h4>                 
              <div class="box-part2" data-aos="fade-right"
              data-aos-offset="100"
              data-aos-easing="ease-in-sine">                              
                <div class="title">
                  <div class="hTx1">Tax Savings + 
                    Wealth Creation</div>
                </div>                            
                <div class="text">
                  <span class="tx1">Extra money is always wonderful. Now 
                    enjoy dual benefit of tax savings and 
                    wealth builder investments that 
                    consistently grow your hard earned money.</span>
                </div>  
                <img class="imgWid1" src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/savingWealth.png" width="100%" alt="">                           
               </div>

               <div class="box-part4" data-aos="fade-right"
               data-aos-offset="100"
               data-aos-easing="ease-in-sine">                              
                <div class="title">
                  <h2 class="hTx1">Everything In One Place</h2>
                </div>                            
                <div class="text">
                  <span class="tx1">Improve your spending habits by seeing 
                    where all your money goes by easily 
                    tracking your investments, expenses, 
                    liabilities. Easier to file returns at the end 
                    of the financial year.</span>
                </div>   
                <img class="imgWid2" src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/onePlace.png" width="100%" alt="">                          
               </div>
            </div> 
           
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">  
                                
              <div class="box-part1" data-aos="fade-left"
              data-aos-offset="100"
              data-aos-easing="ease-in-sine">                              
                <div class="title">
                  <h2 class="hTx1">Less Spending More Saving</h2>
                </div>                            
                <div class="text">
                  <span class="tx1">Have a vacation in mind? Now save 
                    every single rupee legally and treat 
                    yourself with a nice getaway frequently.</span>
                </div>   
                <img class="imgWid3" src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/vacation-gif.gif" width="100%" alt="">                         
               </div>

               <div class="box-part3" data-aos="fade-left"
               data-aos-offset="100"
               data-aos-easing="ease-in-sine">                              
                <div class="title">
                  <h2 class="hTx2">Easy Tax Planning</h2>
                </div>                            
                <div class="text">
                  <span class="tx2">This simple tax planning tool not only helps 
                    you map out short-term purchases like 
                    birthday gifts, but also long-term savings 
                    goals like a house down payment.
                    </span>
                </div>  
                <img class="imgWid4" src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/easyTax.png" width="100%" alt="">                           
               </div>
            </div>	 
            
           
            
        
        </div>		
        </div>
    </div>

    </div>
  </div>
</section>
<section class="homepage-blocks mvTp1">
  <div class="container-fluid">
    <div class="finb-mygoals">
      <h4 class="TpTx">How It Works?</h4>
      <br>
      
        <div class="row">  
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          
            <img class="forWeb" src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/how.gif" width="100%" alt="">               
            <img class="forMob" src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/how_it_works.gif" width="100%" alt="">
          </div>
                
          
        </div>
     
   
    </div>
  </div>
</section>
<section class="homepage-blocks WhyBlock">
  <div class="container">
    <div class="finb-mygoals">
      <h4 class="TpTx">Why Finbingo?</h4>
        <div class="container">
        <div class="row">  
          <div class="col-lg-6 order-lg-1 col-md-6 order-md-1 col-sm-12 order-sm-2 col-12 order-2">
            <div class="button">
              <button type="button" autofocus class="card2"
              onclick=first();>
              <p style="text-align: left;">	<strong>Faster Tax Planning:</strong><br>
          By automating the entire process, we’ve made tax planning faster, smarter and more efficient, at a more reasonable cost. 
                </p>
              </button>
              <button class="card2"
                  onclick=second();>
                  <p style="text-align: left;">	<strong>Expert Advice:</strong><br>
                    Finbingo expert advice is available all year through, at just one tap.
                          </p>
                        </button>
              <button class="card2"
              onclick=third();>
              <p style="text-align: left;">	<strong>AI Driven:</strong><br>
                Is backed by AI which forms the core backend engine, offering you a seamless user experience.
                </p>
              </button>

            </div>
          </div>
          <div class="col-lg-6 order-lg-2 col-md-6 order-md-2 col-sm-12 order-sm-1 col-12 order-1">
            <h2 id="message">
              Faster Tax Planning
            </h2>
            <img id="myImage" src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/fasterTax1.png">
          </div>         
          
        </div>
      </div>
      <p></p>
      <p class="lstLinSps">
        <strong>Let’s create the best investment portfolio for you that will take care of all your tax saving needs, from advice to implementation.</strong>
      </p>
     
      <div class="text-center">
        <a [href]="cmService.portalURL + '/save-tax'" class="btn btn-orangehomepage btnTx">Get Started</a>
      </div>
    </div>
  </div>
</section>
<br>
<br>
<br>