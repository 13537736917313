<section class="manager-descriptionwrap">
  <div class="container">
    <div class="manager-img">
      <img
        src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/sujith-bangar-finbingo-.jpg"
        alt="Sujit Bangar" title="Sujit Bangar">
    </div>
    <h4>Sujit Bangar</h4>
    <h5>Managing Director</h5>
    <div class="manager-social">
      <a href="https://www.linkedin.com/in/sujit-bangar-179962146/" target="_blank">
        <i class="fab fa-linkedin"></i>
      </a>
      <a href="https://twitter.com/sujit_bangar" target="_blank">
        <i class="fab fa-twitter-square"></i>
      </a>
      <!-- <a href="javascript:void(0);">
        <i class="fab fa-instagram"></i>
      </a> -->
      <a href="https://www.facebook.com/sujitb1" target="_blank">
        <i class="fab fa-facebook-square"></i>
      </a>
    </div>
    <div class="manager-description">
      <p>
        Sujit Bangar is a self-starter who has made immense strides in his career at a very young age. After graduating
        in
        Economics & Political Science from S. P. college, Pune, he went to Harvard Business School, Boston, USA for his
        post-graduate degree in Management & Business Administration. He brings years of rich and varied experience to
        drive
        Finbingo’s strategic vision and manage investor relations with aplomb.
      </p>
      <p>
        Prior to founding Finbingo, Sujit worked in the Indian Revenue Service, having joined the department at the age
        of 22.
        He has to his credit the distinction of being the youngest IRS Officer from the batch of 2004.
      </p>
      <p>
        While in the Income Tax Department of the Government of India he worked at various senior levels, amassing
        valuable
        knowledge and experience, before resigning as Joint Commissioner at the age of 35.
      </p>
      <p>
        For Sujit, the learning never stops, whether it is through experiences gained while on the job or by furthering
        his
        education. Towards this he has completed an ‘Advanced Programme in International Taxation & Transfer Pricing’
        from IBFD
        (International Bureau of Fiscal Documentation), Amsterdam, Netherlands. He also studied at IIM Lucknow where he
        successfully completed his Advanced Programme in Management & Tax Administration.
      </p>
      <p>
        It was also during his years at the Income Tax Department that he attended a joint workshop on Tax Reforms with
        the
        French Tax Administration in Paris, France and with the Dutch Tax and Customs Administration at Utrecht,
        Netherlands.
      </p>
      <p>
        Sujit understands the value of mentoring and enjoys spending time with budding entrepreneurs and startups,
        sharing ideas
        and insights with eager minds.
      </p>
      <a href="javascript:void(0);" routerLink="/team-finbingo" class="btn btn-primary">Back to Team Finbingo</a>
      <br>
      <br>
    </div>
  </div>
</section>