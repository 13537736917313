<section class="homepage-blocks">
  <div class="container">
    <div class="row text-center justify-content-center mb-5">
      <div class="col-lg-10 col-xl-8">
        <h1 class="page-bigtitle">Finbingo Mutual Fund Platform</h1>
        <h1 class="page-bigtitle orange">Secure and Fun Investing</h1>

        <div class="text-center startedBtn">
          <a [href]="cmService.portalURL + '/InvestNow/mfInvestment'" class="btn btn-orangehomepage">Get Started</a>
        </div>

        <img
          src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/gif-finbingo-mutual-funds.gif"
          width="100%" alt="Mutual Fund" title="Mutual Fund">
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks bg-light-gray">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-11">
        <div class="right-left-block">
          <div class="finb-feature-content">
            <h4>Buy ‘No commission’ Mutual Funds</h4>
            <p>
              Finbingo is your financial doctor. Just as your doctor suggests you medicines without any commission from
              the
              medicine
              making companies, we sell you Mutual Funds without any commission.
            </p>
            <p>
              No bias in our advice, <strong>guaranteed.</strong>
            </p>
          </div>
          <div class="finb-featureimg">
            <img
              src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/buy_no_commission_MF-1-300x238.png"
              alt="No Commission" title="No Commission">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="homepage-blocks">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-11">
        <div class="left-right-block">
          <div class="finb-featureimg">
            <img
              src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/choose_ur_fund-300x258.png"
              alt="Choose Mutual Fund" title="Choose Mutual Fund">
          </div>
          <div class="finb-feature-content">
            <h4>Quickly choose your funds</h4>
            <p>
              Find the needle in the haystack! We help you choose your funds with our advanced filters and ratings
              system.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="homepage-blocks bg-light-gray">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-11">
        <div class="right-left-block">
          <div class="finb-feature-content">
            <h4>Globalise Your Wealth</h4>
            <p>
              Diversify your Investment Portfolio with Finbingo. Buy Thematic-International Mutual funds & get exposure to global companies you love. 
            </p>
            <p>
              <strong>Reduce portfolio risk</strong> as markets around the world rarely go down together.              
            </p>
          </div>
          <div class="finb-featureimg">
            <img
              src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/GlobalWealth-300x258.png"
              alt="Globalise Your Wealth" title="Globalise Your Wealth">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="infowith-iconswrap">
  <div class="container">
    <div class="infowith-icons">
      <div class="infowith-icon-content">
        <img
          src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/2-icon1_start_auto_investin.png"
          alt="Auto Investing" title="Auto Investing">
        <div>
          <h4>Start automatic <br> Investing</h4>
          <a [href]="cmService.portalURL + '/InvestNow/mfInvestment'">Explore SIP Now <i
              class="fas fa-angle-right"></i></a>
        </div>
      </div>
      <div class="infowith-icon-content">
        <img
          src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/2-icon2_start_annuity_proce.png"
          alt="Start Process" title="Start Process">
        <div>
          <h4>Start annuity <br> process</h4>
          <a [href]="cmService.portalURL + '/InvestNow/mfInvestment'">Explore SWP Now <i
              class="fas fa-angle-right"></i></a>
        </div>
      </div>
      <div class="infowith-icon-content">
        <img
          src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/2-icon3_transfermoney.png"
          alt="STP" title="STP">
        <div>
          <h4>Transfer money from one fund to another</h4>
          <a [href]="cmService.portalURL + '/InvestNow/mfInvestment'">Explore STP Now <i
              class="fas fa-angle-right"></i></a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="homepage-blocks bg-light-gray mt-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-11">
        <div class="left-right-block">
          <div class="finb-feature-content">
            <h4>Analyze your previous investments</h4>
            <p>
              Get a checkup of your portfolio by Finbingo’s Portfolio Doctor and keep your mental health and wealth in
              best
              condition!
            </p>
          </div>
          <div class="finb-featureimg">
            <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/TPA-PNG1.png"
              alt="Analyze Investments" title="Analyze Investments">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="homepage-blocks">
  <div class="container">
    <div class="finb-mygoals">
      <h4>Get Started Today!</h4>
      <br>
      <p>
        <strong>The best time to begin investing in your financial future was yesterday; the next best is
          today!</strong>
        <br>
        <br>
        We have a set of tools for deciding the better performing mutual funds that can assist you in wealth building.
      </p>
      <div class="text-center">
        <a [href]="cmService.portalURL + '/InvestNow/mfInvestment'" class="btn btn-orangehomepage">Get Started</a>
      </div>
    </div>
  </div>
</section>
