<footer>
  <div class="footer-top">
    <div class="container">
      <div class="footer-toplinks">
        <div class="footer-toplinkswrap">
          <h3>Offerings</h3>
          <ul class="footer-toplinks-list">
            <li>
              <a routerLink="/wealth-builder">
                Finbingo Wealth Builder
              </a>
            </li>
            <li>
              <a routerLink="/tax-planning-advisor">
                Finbingo Tax Planner
              </a>
            </li>
            <li>
              <a routerLink="/financial-life-goals">
                Financial Life Goals
              </a>
            </li>
            <li>
              <a routerLink="/partners">
                Partner with us
              </a>
            </li>
          </ul>
        </div>
        <div class="footer-toplinkswrap">
          <h3>Legal</h3>
          <ul class="footer-toplinks-list">
            <li>
              <a routerLink="/privacy-policy">
                Privacy Policy
              </a>
            </li>
            <li>
              <a routerLink="/terms-and-conditions">
                Terms & Conditions
              </a>
            </li>
            <li>
              <a routerLink="/disclosure">
                Regulatory Disclosure
              </a>
            </li>
            <li>
              <a routerLink="/grievance">
                Grievance
              </a>
            </li>
          </ul>
        </div>
        <div class="footer-toplinkswrap">
          <h3>About</h3>
          <ul class="footer-toplinks-list">
            <li>
              <a routerLink="/what-is-finbingo-robo-advisor">
                What is Finbingo?
              </a>
            </li>
            <li>
              <a routerLink="/our-story">
                Our Story
              </a>
            </li>
            <li>
              <a routerLink="/team-finbingo">
                Team
              </a>
            </li>
            <li>
              <a routerLink="/investor-charter">
                Investor Charter
              </a>
            </li>
          </ul>
        </div>
        <div class="footer-toplinkswrap">
          <h3>Contact</h3>
          <ul class="footer-toplinks-list">
            <li>
              <a routerLink="/contact">
                Get in touch
              </a>
            </li>
            <li>
              <a routerLink="/contact">
                Call Now
              </a>
            </li>
            <li>
              <a routerLink="/contact">
                Meet us
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- <ul class="bse-number">
        <li>
          <img width="32px" src="https://finbingo.com/wp-content/uploads/2019/06/bse-star-mf.png" alt="">
          BSE Registered Mutual Fund Distributor - CODE NO: 15048
        </li>
        <li>
          <img width="32" src="https://www.finbingo.com/wp-content/uploads/2020/01/new-sebi-logo.png" alt="">
          SEBI Registered Investment Advisor - INA000012166
        </li>
      </ul> -->
      <div class="finb-footerinfo">
        <div class="row justify-content-between">
          <div class="col-md-3">
            <img class="white-logo" src="https://finbingo.com/wp-content/uploads/2019/06/finbingo-logo-footer.png" alt="">
            <ul class="footer-sociallinks">
              <li>
                <a class="fbicon" href="https://www.facebook.com/FinBingo" target="_blank">
                  <i class="fab fa-facebook-square"></i>
                </a>
              </li>
              <li>
                <a class="twittericon" href="https://twitter.com/MyFinBingo" target="_blank">
                  <i class="fab fa-twitter-square"></i>
                </a>
              </li>
              <li>
                <a class="linkedicon" href="https://www.linkedin.com/company/finbingo" target="_blank">
                  <i class="fab fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a class="instaicon" href="https://www.instagram.com/finbingo/" target="_blank">
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-8">
            <p>
              Mutual fund investments are subject to market risks. Please read the scheme information and other related documents
              carefully before investing. Past performance is not indicative of future returns.
            </p>
            <p>
              Please consider your specific investment requirements, risk tolerance, investment goal, time frame, risk and reward
              balance and the cost associated with the investment before choosing a fund, or designing a portfolio that suits your
              needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="container clearfix">
      <p class="footer-info">Copyright © {{currentYear}} <a href="https://www.finbingo.com/" rel="home">SSBA Innovations Ltd.</a>
      </p>
    </div>
  </div>
</footer>