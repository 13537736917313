import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-atul-rege',
  templateUrl: './atul-rege.component.html',
  styleUrls: ['./atul-rege.component.css']
})
export class AtulRegeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
