<section class="manager-descriptionwrap">
    <div class="container">
        <div class="manager-img">
            <img src="https://s3.ap-south-1.amazonaws.com/assets.finbingo.com/Finbingo/images/pre-render/Vidyasagar.jpg"
                alt="Vidyasagar Patil" title="Vidyasagar Patil">
        </div>
        <h4>Vidyasagar Patil</h4>
        <h5>Co-Founder</h5>
        <div class="manager-social">
            <a href="https://www.linkedin.com/in/vidyasagar-patil-3155bb67/" target="_blank">
                <i class="fab fa-linkedin"></i>
            </a>
            <!-- <a href="javascript:void(0);">
                <i class="fab fa-twitter-square"></i>
            </a>
            <a href="javascript:void(0);">
                <i class="fab fa-instagram"></i>
            </a>
            <a href="javascript:void(0);">
                <i class="fab fa-facebook-square"></i>
            </a> -->
        </div>
        <div class="manager-description">
            <p>
                Vidyasagar Patil is Doctor by qualification and belongs to the 2004 batch of the IRS. In his 15 years of
                service with the Indian Revenue Service (IRS), he has worked at various senior levels in the Ministry of
                Finance, and the Department of Revenue.
            </p>
            <p>
                In December 2019, he resigned from IRS as an Additional Commissioner of Income Tax and joined Taxbuddy.
                He aspires to make taxation simplified by leveraging technology. Vidyasagar is an MBA graduate from
                Nanyang Business School, Singapore, who has cleared all CFA levels.
            </p>

            <a href="javascript:void(0);" routerLink="/team-finbingo" class="btn btn-primary">Back to Team Finbingo</a>
            <br>
            <br>
        </div>
    </div>
</section>