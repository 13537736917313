import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-financial-calculators',
  templateUrl: './financial-calculators.component.html',
  styleUrls: ['./financial-calculators.component.css']
})
export class FinancialCalculatorsComponent implements OnInit {

  constructor(public cmService: CommonService) { }

  ngOnInit(): void {
  }

  redirectToCal(cal){
    if(cal === 'SIP'){
      window.location.href = this.cmService.portalURL+'/calculator/sip-calc';
    }
    else if(cal === 'GOAL-SIP'){
      window.location.href = this.cmService.portalURL+'/calculator/goal-calc';
    }
    else if(cal === 'HLV'){
      window.location.href = this.cmService.portalURL+'/calculator/hlv-calc';
    }
    else if(cal === 'NEW-BUDGET-TAX-CAL'){
      window.location.href = this.cmService.portalURL+'/calculator/new-budget-calc';
    }
  }

}
